import { useMemo } from "react";
import { Heading, Table, Thead, Tr, Th, Td, Tbody } from "@chakra-ui/react";

import { formatDate } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import Link from "@svix/common/widgets/Link";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import {
  AdminApi,
  OrganizationGroupRow,
  StatsOut,
} from "src/generated/dashboard-openapi";
import { OrganizationIdAndEnvironment } from "src/generated/dashboard-openapi/models/OrganizationIdAndEnvironment";
import { genericCmp, useLoading } from "src/utils";

type SortKey = keyof StatsOut["orgGroups"][0] | "id";

function genericEmail(email: string): boolean {
  const parts = email.split("@");
  // The email may be a full email, including name, so "John Doe <j.doe@mail.com>"
  // So we want to remove the suffix, and lower case to make matching easy
  const domain = parts[1].toLowerCase().replace(/>$/g, "");
  const genericDomains = ["gmail.com", "aol.com", "yahoo.com"];
  return !!genericDomains.find((x) => x === domain);
}

export default function AdminStats() {
  const sortField: SortKey = "createdAt";
  const [stats] = useLoading(async () => {
    const config = await getApiConfiguration();
    const adminApi = new AdminApi(config);
    return adminApi.statsAdminStatsGet();
  }, []);

  const sortedStats = useMemo(() => {
    if (!stats) {
      return undefined;
    }

    return Object.keys(stats.orgGroups)
      .map((id) => ({ id, ...stats.orgGroups[id] }))
      .sort((a, b) => genericCmp(a[sortField], b[sortField]));
  }, [stats, sortField]);

  if (!stats || !sortedStats) {
    return <LoadingIndicator />;
  }

  const columns: {
    title: string;
    accessor: SortKey;
    format?: (x: any, item: OrganizationGroupRow) => string | JSX.Element;
  }[] = [
    {
      title: "Created",
      accessor: "createdAt",
      format: (x: string) => (
        <span style={{ whiteSpace: "nowrap" }}>{formatDate(new Date(x))}</span>
      ),
    },
    {
      title: "Emails",
      accessor: "emails",
      format: (emails: string[], item: OrganizationGroupRow) => {
        const style = item.deleted ? { textDecoration: "line-through" } : {};
        return (
          <>
            {emails.map((x) => (
              <span
                key={x}
                style={{ ...style, color: genericEmail(x) ? "gray" : undefined }}
              >
                {x}
                <br />
              </span>
            ))}
          </>
        );
      },
    },
    {
      title: "Organization IDs",
      accessor: "orgs",
      format: (orgs: OrganizationIdAndEnvironment[]) => {
        return (
          <>
            {orgs.map((x) => (
              <Link
                key={x.id}
                to={routeResolver.getRoute("admin-stats._orgid", { orgid: x.id })}
                display="block"
                whiteSpace="nowrap"
              >
                {x.id} [{x.env[0]}.{x.reg}]
              </Link>
            ))}
          </>
        );
      },
    },
    {
      title: "ID",
      accessor: "id",
    },
  ];

  return (
    <>
      <Heading as="h1" size="md" mb={4}>
        Admin Stats
      </Heading>
      <Card>
        <h2>Accounts stats ({sortedStats.length})</h2>
        <div style={{ overflowX: "auto" }}>
          <Table>
            <Thead>
              <Tr>
                {columns.map((x) => (
                  <Th key={x.title}>{x.title}</Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {sortedStats.map((x) => (
                <Tr key={x.id} cursor="pointer">
                  {columns.map((y) => (
                    <Td key={y.accessor} fontFamily="mono">
                      {y.format ? y.format(x[y.accessor], x) : String(x[y.accessor])}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
      </Card>
    </>
  );
}
