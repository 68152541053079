import { Heading, Stack } from "@chakra-ui/react";

import usePagination from "@svix/common/hooks/pagination";

import { getSvix } from "src/api";
import { ListSourceOut, SourcesApi } from "src/api/in";
import { useAppSelector } from "src/hooks/store";
import CreateSource from "./CreateSource";
import SendRequests from "./SendRequests";
import SetupDestinations from "./SetupDestinations";

export default function GettingStarted() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const [sources, _] = usePagination<ListSourceOut>(
    ["environments", activeEnvId, "ingest", "sources"],
    async (_iterator) => {
      const sv = await getSvix();
      const api = new SourcesApi(sv);
      return api.list(50);
    }
  );

  const source = sources?.data.at(0);

  return (
    <Stack spacing={4}>
      <Heading as="h2" size="lg">
        Getting Started
      </Heading>
      <Stack spacing={2} maxW="50rem">
        <CreateSource createdSource={source} />
        <SendRequests createdSource={source} />
        <SetupDestinations createdSource={source} />
      </Stack>
    </Stack>
  );
}
