import { Heading, Box, Alert, Table, Thead, Tr, Th, Td, Tbody } from "@chakra-ui/react";
import { useParams } from "react-router";

import { formatDate, formatDateTime } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";

import { getApiConfiguration } from "src/api";
import { AdminApi } from "src/generated/dashboard-openapi";
import { genericCmp, useLoading } from "src/utils";

function addDaysToDate(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

interface IDayCountRow {
  date: Date;
  count: number;
}

export default function AdminDetails() {
  const { orgid: orgId } = useParams<{ orgid: string }>();

  const [result] = useLoading(async () => {
    const config = await getApiConfiguration();
    const adminApi = new AdminApi(config);
    const now = new Date();
    return adminApi.getAdminOrgMessagesStatsAdminStatsOrgMessagesGet(
      orgId,
      new Date(now.getFullYear(), now.getMonth() - 1, 1), // start of the last month
      now
    );
  }, []);

  // Sanitize response object
  if (!result?.data.messageDestinationCount) {
    return (
      <>
        <Box my={2}>
          <Alert status="error">Error</Alert>
        </Box>
      </>
    );
  }

  // Generate daily row records from response data
  let dailyRows: IDayCountRow[] = [];
  dailyRows = dailyRows.concat(
    result.data.messageDestinationCount.map((x, idx): IDayCountRow => {
      return {
        date: addDaysToDate(result.startDate, idx),
        count: x,
      };
    })
  );
  dailyRows.sort((a, b) => genericCmp(a.date, b.date));

  // Calculate the end of the last month AKA start of the curr month
  // Don't use `startDateLastMonth` to guarantee time is 00:00:00 UTC instead of 00:00:00 Local
  const statDataLastMonthEndDate = new Date(result.startDate);
  statDataLastMonthEndDate.setUTCMonth(result.startDate.getUTCMonth() + 1);

  // Calculate totals for last month and this month
  const lastMonthTotal = dailyRows
    .filter((dcr) => dcr.date < statDataLastMonthEndDate)
    .map((dcr) => dcr.count)
    .reduce((total, curr) => total + curr);
  const thisMonthTotal = dailyRows
    .filter((dcr) => dcr.date >= statDataLastMonthEndDate)
    .map((dcr) => dcr.count)
    .reduce((total, curr) => total + curr);

  return (
    <>
      <Heading as="h1" size="lg" mb={4}>
        Admin Stats: {orgId}
      </Heading>
      <Card my={2}>
        <Heading as="h2" size="md" mb={4}>
          Message Destination Count: Summary
        </Heading>
        <Box overflowX="auto">
          <Table>
            <Thead>
              <Tr>
                <Th>Start Date</Th>
                <Th>End Date</Th>
                <Th>Count</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>{formatDateTime(result.startDate, { utc: true })}</Td>
                <Td>{formatDateTime(statDataLastMonthEndDate, { utc: true })}</Td>
                <Td>{lastMonthTotal}</Td>
              </Tr>
              <Tr>
                <Td>{formatDateTime(statDataLastMonthEndDate, { utc: true })}</Td>
                <Td>{formatDateTime(result.endDate, { utc: true })}</Td>
                <Td>{thisMonthTotal}</Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Card>
      <Card my={2}>
        <Heading as="h2" size="md" mb={4}>
          Message Destination Count: Day-to-Day
        </Heading>
        <Box overflowX="auto">
          <Table>
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Count</Th>
              </Tr>
            </Thead>
            <Tbody>
              {dailyRows.map((x: IDayCountRow, idx: number) => (
                <Tr key={idx}>
                  <Td>{formatDate(x.date, { utc: true })}</Td>
                  <Td>{x.count}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Card>
    </>
  );
}
