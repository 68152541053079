/**
 * Dashboard
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * OpenAPI spec version: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { InvoiceLineOut } from './InvoiceLineOut';
import { StripeCoupon } from './StripeCoupon';
import { SubscriptionEnum } from './SubscriptionEnum';
import { HttpFile } from '../http/http';

export class SubscriptionOut {
    'coupons': Array<StripeCoupon>;
    'discount': number;
    'isLegacy': boolean;
    'lines': Array<InvoiceLineOut>;
    'nextChargeAt'?: Date;
    'periodEnd': Date;
    'periodStart': Date;
    'planName': SubscriptionEnum;
    'planStartedAt': Date;
    'quantity': number;
    'remainingFreeMessages': number;
    'total': number;

    static readonly discriminator: string | undefined = undefined;

    static readonly attributeTypeMap: Array<{name: string, baseName: string, type: string, format: string}> = [
        {
            "name": "coupons",
            "baseName": "coupons",
            "type": "Array<StripeCoupon>",
            "format": ""
        },
        {
            "name": "discount",
            "baseName": "discount",
            "type": "number",
            "format": ""
        },
        {
            "name": "isLegacy",
            "baseName": "isLegacy",
            "type": "boolean",
            "format": ""
        },
        {
            "name": "lines",
            "baseName": "lines",
            "type": "Array<InvoiceLineOut>",
            "format": ""
        },
        {
            "name": "nextChargeAt",
            "baseName": "nextChargeAt",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "periodEnd",
            "baseName": "periodEnd",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "periodStart",
            "baseName": "periodStart",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "planName",
            "baseName": "planName",
            "type": "SubscriptionEnum",
            "format": ""
        },
        {
            "name": "planStartedAt",
            "baseName": "planStartedAt",
            "type": "Date",
            "format": "date-time"
        },
        {
            "name": "quantity",
            "baseName": "quantity",
            "type": "number",
            "format": ""
        },
        {
            "name": "remainingFreeMessages",
            "baseName": "remainingFreeMessages",
            "type": "number",
            "format": ""
        },
        {
            "name": "total",
            "baseName": "total",
            "type": "number",
            "format": ""
        }    ];

    static getAttributeTypeMap() {
        return SubscriptionOut.attributeTypeMap;
    }
    
    public constructor() {
    }
}

