import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";

import * as C from "@svix/common/constants";
import { sentryErrorFilter } from "@svix/common/logger";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store, persistor } from "./store";
import { isEE } from "./utils";
import "./analytics";
import "./index.css";
// Polyfills
import "url-search-params-polyfill";

Sentry.init({
  enabled: import.meta.env.NODE_ENV !== "development",
  dsn: C.sentry.dashboardDsn,
  environment: C.envConfig.name,
  integrations: [Sentry.browserTracingIntegration(), Sentry.httpClientIntegration()],
  beforeSend: sentryErrorFilter,
  // Performance Monitoring
  tracesSampleRate: 0.2,
});

const container = document.getElementById("root")!;
const root = createRoot(container);
root.render(
  // FIXME #9497: StrictMode doesn't work with react-router-dom v5. See https://github.com/remix-run/react-router/issues/7870.
  // Update once we move to v6.
  // <StrictMode>
  <Sentry.ErrorBoundary>
    {/* FIXME: this can be moved further down in the tree to avoid having to check isEE here */}
    <BrowserRouter basename={isEE ? "/admin" : "/"}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </Sentry.ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
