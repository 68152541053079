import * as React from "react";
import { Box, Button, Link, Heading, Text, Alert, AlertIcon } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { logError } from "@svix/common/logger";
import { sleep } from "@svix/common/utils";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";

export default function EventTypesGenerateSDKScreen() {
  enum TaskState {
    InProgress,
    Error,
    Success,
  }

  const { taskId } = useParams<{ taskId: string }>();
  const [sdkURL, setSdkURL] = React.useState("");
  const [taskState, setTaskState] = React.useState(TaskState.InProgress);

  React.useEffect(() => {
    let cancelled = false;

    async function pollTask() {
      const api = await getSvix();
      let lastPoll = Date.now();

      const POLL_INTERVAL = 2000;

      while (!cancelled) {
        const res = await api.backgroundTask.get(taskId);
        if (res.status === "finished") {
          setSdkURL(res.data.schemaZip);
          setTaskState(TaskState.Success);
          break;
        } else if (res.status === "running") {
          const curTime = Date.now();
          if (curTime - lastPoll < POLL_INTERVAL) {
            await sleep(POLL_INTERVAL - (curTime - lastPoll));
          }
          lastPoll = curTime;
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        } else if (res.status === "failed") {
          logError("SDK generation task failed: " + res.id);
          setTaskState(TaskState.Error);
          break;
        } else {
          logError(
            "SDK generation task in unknown state: " + res.status + " for " + res.id
          );
          setTaskState(TaskState.Error);
          break;
        }
      }
    }

    pollTask().catch(function (e) {
      logError(e, "Exception generating sdk");
      setTaskState(TaskState.Error);
    });

    return () => {
      cancelled = true;
    };
  });

  const pendingorSuccessButton = (
    <Button
      colorScheme="brand"
      loadingText="Generating..."
      isLoading={taskState === TaskState.InProgress}
    >
      Download generated SDK types
    </Button>
  );

  let generateButton = null;

  if (taskState === TaskState.Success) {
    generateButton = <Link href={sdkURL}>{pendingorSuccessButton}</Link>;
  } else if (taskState === TaskState.InProgress) {
    generateButton = pendingorSuccessButton;
  } else {
    generateButton = (
      <Alert status="error">
        <AlertIcon />
        An error occurred generating SDK types
      </Alert>
    );
  }

  return (
    <>
      <MetaTitle path={["Generate SDK types"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("event-types")}>
            Event Types
          </BreadcrumbItem>
          <BreadcrumbItem>Generate SDK types</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>

      <Box py={6} borderTop="1px solid" borderColor="background.modifier.border">
        <Heading as="h3" size="sm" mb={2}>
          SDK types from OpenAPI
        </Heading>
        <Text>
          We provide Rust and TypeScript type definitions, generated from the OpenAPI
          specification you provide for your events.
          <br />
          These can be used with Svix client libraries to provide type-safe webhook
          sending.
        </Text>
      </Box>

      <div>
        <Box mt={2} mb={6}>
          {generateButton}
        </Box>
      </div>
    </>
  );
}
