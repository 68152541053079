import { useState, useEffect } from "react";
import {
  Alert,
  AlertTitle,
  AlertDescription,
  Box,
  Grid,
  Divider,
  Link as StyledLink,
  Heading,
  HStack,
  Text,
  Flex,
  List,
  ListItem,
  ListIcon,
  Skeleton,
  Stack,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Check, OpenInNew } from "@material-ui/icons";
import { times } from "lodash";
import { useForm } from "react-hook-form";
import { useQueryClient, useQuery } from "react-query";
import * as yup from "yup";

import * as C from "@svix/common/constants";
import { setErrors } from "@svix/common/formUtils";
import { logError } from "@svix/common/logger";
import { formatCurrency, formatDate } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import ResourceError from "@svix/common/widgets/ResourceError";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { trackEvent } from "src/analytics";
import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import {
  BillingApi,
  SubscriptionOut,
  BillingOut,
  ApiException,
  SubscriptionEnum,
  BillingAlertsIn,
  StripeCoupon,
} from "src/generated/dashboard-openapi";
import { Plan, planColorAccents } from "./PaymentPlanCard";

export function getBillingPeriod(invoice?: SubscriptionOut): React.ReactNode {
  if (!invoice) {
    return "";
  }

  const from = invoice.periodStart.toLocaleString(undefined, {
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  });

  const to = invoice.periodEnd.toLocaleString(undefined, {
    month: "short",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  });

  return `(${from} - ${to})`;
}

export default function BillingScreen() {
  const toast = useToast();
  const {
    data: billing,
    error: subscriptionError,
    refetch,
  } = useQuery<SubscriptionOut, Error>(["billing", "subscription"], async () => {
    const config = await getApiConfiguration();
    const billingApi = new BillingApi(config);
    return billingApi.getSubscriptionDetailsBillingSubscriptionGet();
  });

  const { data: billingUrl, error: billingError } = useQuery<BillingOut, Error>(
    "billing",
    async () => {
      const config = await getApiConfiguration();
      const api = new BillingApi(config);
      return api.getStripeDashboardLinkBillingGet();
    }
  );

  const { data: paymentMethods } = useQuery(["billing", "paymentMethods"], async () => {
    const config = await getApiConfiguration();
    const billingApi = new BillingApi(config);
    return billingApi.getPaymentMethodsBillingPaymentMethodsGet();
  });

  const schema = yup.object().shape({
    alertThreshold: yup
      .number()
      .nullable()
      .transform((value: string, originalValue: string | null) =>
        !originalValue || originalValue.trim() === "" ? null : value
      ),
  });

  const { data: billingAlerts } = useQuery("billingAlerts", async () => {
    const config = await getApiConfiguration();
    const orgGroupApi = new BillingApi(config);
    return await orgGroupApi.getBillingAlertsBillingAlertsGet();
  });

  const formCtx = useForm<BillingAlertsIn>({
    resolver: yupResolver(schema),
    defaultValues: billingAlerts,
    mode: "onBlur",
  });

  const { reset } = formCtx;
  useEffect(() => {
    reset(billingAlerts);
  }, [reset, billingAlerts]);

  async function saveBillingAlerts(values: BillingAlertsIn) {
    const config = await getApiConfiguration();
    const orgGroupApi = new BillingApi(config);
    try {
      const settings = await orgGroupApi.updateBillingAlertsBillingAlertsPatch(values);
      reset(settings);
      toast({ status: "success", title: "Billing Alerts Saved" });
      trackEvent("Customize Billing Alerts");
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  }

  const {
    data: plans,
    isLoading: loadingPlans,
    error: plansError,
    refetch: refetchPlans,
  } = usePaymentPlans();

  if (subscriptionError) {
    logError(subscriptionError);

    let customMsg;
    if (
      subscriptionError instanceof ApiException &&
      subscriptionError.body.code === "permission_denied"
    ) {
      customMsg = "Only admins can access the Billing page.";
    }
    return <ResourceError resourceName="billing" message={customMsg} onClick={refetch} />;
  }

  if (billingError) {
    logError(billingError);

    let customMsg;
    if (
      billingError instanceof ApiException &&
      billingError.body.code === "permission_denied"
    ) {
      customMsg = "Only admins can access the Billing page.";
    }
    return <ResourceError resourceName="billing" message={customMsg} onClick={refetch} />;
  }

  if (plansError) {
    return <ResourceError resourceName="payment plans" onClick={refetchPlans} />;
  }

  return (
    <>
      <MetaTitle path={["Billing"]} />
      <Heading as="h1" mr={4} size="lg">
        Plans
      </Heading>
      {billing?.isLegacy && (
        <Box my=".5em" maxW="60em">
          <Alert status="info" shadow="md" rounded="md">
            <AlertIcon />
            <Box>
              <Text>
                You are using a custom pricing plan. You can continue using it, or change
                it to one of the standard pricing plans below.
              </Text>
              <Text>
                <StyledLink textDecoration="underline" href="mailto:support@svix.com">
                  Contact us
                </StyledLink>{" "}
                if you have any questions.
              </Text>
            </Box>
          </Alert>
        </Box>
      )}
      <Box my=".5em">
        <Text>
          Choose the plan that's right for you. Not sure which to choose? Explore and
          compare plans on the{" "}
          <Text
            as="a"
            href={C.pricingUrl}
            target="new"
            color="blue.500"
            decoration="underline"
          >
            Pricing page
          </Text>
          .
        </Text>
      </Box>
      <Box mt=".5em" maxW="80em">
        <Grid
          gridTemplateColumns={{
            sm: "minmax(1, 1fr)",
            md: "minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)",
          }}
          gap={4}
        >
          {loadingPlans &&
            times(3).map((_, i) => {
              return <Skeleton key={`card-skeleton-${i}`} h="360px" w="100%" />;
            })}

          {plans?.map((plan, index) => (
            <PlanCard
              key={index}
              current={billing?.planName || "free"}
              plan={plan}
              active={!billing?.isLegacy && billing?.planName === plan.planName}
              refetch={refetch}
            />
          ))}
        </Grid>
        {paymentMethods &&
          paymentMethods.data.length === 0 &&
          billing?.planName === "starter" && (
            <Box mt={6}>
              <Alert status="warning" borderRadius="md" variant="plain">
                <AlertIcon />
                <HStack justifyContent="space-between" w="100%">
                  <Box>
                    <AlertTitle>Increase account limits</AlertTitle>
                    <AlertDescription>
                      <Text>Add a payment method to increase your account's limits.</Text>
                    </AlertDescription>
                  </Box>
                  <Button
                    as="a"
                    href={routeResolver.getRoute("billing.payment._planName", {
                      planName: "starter",
                    })}
                    size="sm"
                  >
                    Add payment method
                  </Button>
                </HStack>
              </Alert>
            </Box>
          )}
        <Stack spacing={4} mt={12}>
          <Flex direction="row" align="center">
            <Heading as="h1" mr={4} size="lg">
              Billing & Invoices
            </Heading>
            <Skeleton isLoaded={!!billingUrl} display="inline" maxW="16em">
              <Button
                as="a"
                href={billingUrl?.billingDashboardUrl}
                target="new"
                size="sm"
                colorScheme="blue"
                shadow="md"
              >
                Manage Billing Information
                <OpenInNew fontSize="small" style={{ marginLeft: ".25em" }} />
              </Button>
            </Skeleton>
          </Flex>
          <Card title={`Upcoming Invoice ${getBillingPeriod(billing)}`} w="100%">
            {billing && <CouponAlert coupons={billing.coupons} />}
            {billing?.lines.map((line, idx) => (
              <Box display="flex" justifyContent="flex-end" py={1} key={idx}>
                <Text fontWeight="medium">{line.description}</Text>
              </Box>
            ))}
            <Divider my={2} />
            {billing?.discount !== undefined && billing.discount !== 0 && (
              <>
                <Box display="flex" justifyContent="space-between" alignItems="baseline">
                  <Text variant="caption">Discounts</Text>
                  <Text fontSize="lg" fontWeight="semibold" letterSpacing="wide">
                    -{formatCurrency(billing.discount)}
                  </Text>
                </Box>
                <Divider my={2} />
              </>
            )}
            {billing && (
              <Box display="flex" justifyContent="space-between" alignItems="baseline">
                {billing.nextChargeAt && (
                  <Text variant="caption">Due {formatDate(billing.nextChargeAt)}</Text>
                )}
                {!billing.nextChargeAt && (
                  <Text variant="caption">
                    Account balance will not be invoiced automatically
                  </Text>
                )}
                <Text fontSize="lg" fontWeight="semibold" letterSpacing="wide">
                  {formatCurrency(billing.total)}
                </Text>
              </Box>
            )}
          </Card>
          <Card title="Billing Alerts" maxW="50em">
            <Text size="md" variant="caption">
              An email alert will be sent to all the organization admins when the usage
              exceeds this threshold.
            </Text>
            <Form onSubmit={saveBillingAlerts} {...formCtx}>
              <Stack spacing={4} mt={4} width="100%">
                <TextField
                  maxW="24em"
                  control={formCtx.control}
                  name="alertThreshold"
                  label="Alert threshold (messages per month)"
                  placeholder="For example: 10000"
                  type="number"
                />
                <GeneralFormErrors />
              </Stack>
              <Box mt={4}>
                <Divider />
                <SubmitButton
                  mt={4}
                  type="submit"
                  variant="solid"
                  loadingText="Saving"
                  showLoading
                >
                  Save
                </SubmitButton>
              </Box>
            </Form>
          </Card>
          <Text variant="caption" size="sm" mt={2}>
            Billing information is updated daily. All counts and related billing
            information are subject to a delay.
          </Text>
        </Stack>
      </Box>
    </>
  );
}

function CouponAlert(props: { coupons: StripeCoupon[] }) {
  const validCoupons = props.coupons.filter((coupon) => coupon.valid);

  if (validCoupons.length === 0) {
    return null;
  }

  return (
    <Alert
      status="success"
      display="flex"
      py={1}
      borderRadius="md"
      variant="subtle"
      mb={2}
    >
      <Text fontWeight="medium">
        Discount applied: {validCoupons.map((coupon) => coupon.name).join(", ")}
      </Text>
    </Alert>
  );
}

interface PlanCardProps {
  plan: Plan;
  current: SubscriptionEnum;
  active: boolean;
  refetch: () => void;
}

function PlanCard(props: PlanCardProps) {
  const queryClient = useQueryClient();

  const [confirmFreeVisible, setConfirmFreeVisible] = useState(false);

  const confirmFree = async () => {
    const config = await getApiConfiguration();
    const billingApi = new BillingApi(config);
    await billingApi.changeSubscriptionBillingSubscriptionPatch({
      planName: "free",
    });
    await queryClient.invalidateQueries("billing");
    props.refetch();
    setConfirmFreeVisible(false);
  };

  return (
    <>
      <Stack
        borderWidth={props.active ? "3px" : "1px"}
        rounded="md"
        shadow={props.active ? "lg" : "sm"}
        bgColor="background.secondary"
        borderColor={props.plan.accent}
        padding={0}
        spacing={0}
        overflow="hidden"
      >
        <Box padding="1em" bgColor={props.plan.accent}>
          <Text fontSize="3xl" fontWeight="semibold" color="white">
            {props.plan.title}
          </Text>
          <Text fontSize="lg" color="white">
            {props.plan.price}
            {props.plan.planName !== "enterprise" && " / month"}
          </Text>
        </Box>
        <Stack justifyContent="space-between" padding="1em" h="100%">
          <List mt="1em" minH="13em">
            {props.plan.lineItems.map((line, index) => (
              <ListItem key={index}>
                <ListIcon as={Check} color={props.plan.accent} />
                {line}
              </ListItem>
            ))}
          </List>
          <Box w="100%" mt="2em">
            {props.active ? (
              <Button
                as={Text}
                size="sm"
                bgColor={props.plan.accent}
                color="white"
                _hover={{}}
                cursor="default"
              >
                Selected
              </Button>
            ) : (
              <>
                {props.plan.planName === "enterprise" ? (
                  <Button
                    as="a"
                    href={C.contactUrl}
                    target="new"
                    size="sm"
                    colorScheme="gray"
                  >
                    Contact Sales
                  </Button>
                ) : (
                  <Button
                    as="a"
                    href={routeResolver.getRoute("billing.payment._planName", {
                      planName: props.plan.planName,
                    })}
                    size="sm"
                    colorScheme="gray"
                  >
                    {getCTAText(props.current, props.plan)}
                  </Button>
                )}
              </>
            )}
          </Box>
        </Stack>
      </Stack>
      {props.plan.planName === "free" && (
        <ConfirmationDialog
          title="Downgrade subscription?"
          isOpen={confirmFreeVisible}
          onCancel={() => setConfirmFreeVisible(false)}
          onOk={confirmFree}
          labelOk="Downgrade"
          colorScheme="red"
        >
          <Text>
            Are you sure you want to downgrade your subscription? You will be invoiced at
            the end of the month for your current outstanding balance and your
            organization will be switched to the Free plan.
          </Text>
          <Text mt="1em">
            The Free plan is subject to rate limiting and has a maximum of 50,000 per
            month.
          </Text>
        </ConfirmationDialog>
      )}
    </>
  );
}

type PricingPlan = {
  planId: SubscriptionEnum;
  name: string;
  price?: number;
  description: string;
  features: string[];
};

type PricingPlans = {
  [key in SubscriptionEnum]?: PricingPlan;
};

export const usePaymentPlans = () => {
  return useQuery<Plan[]>("pricing", async () => {
    try {
      const res: PricingPlans = await fetch(`${C.homePage}api/pricing/plans/`).then(
        (res) => res.json()
      );
      // TODO: it would be better to just return the plans in the correct order from the API.
      const sortedPlans: SubscriptionEnum[] = [
        "free",
        "starter",
        "business",
        "enterprise",
      ];
      return sortedPlans
        .filter((p) => !!res[p])
        .map((planName) => {
          const { name, features, price } = res[planName]!;
          return {
            planName,
            accent: planColorAccents[planName],
            title: name,
            lineItems: features,
            price: planName === "enterprise" ? "Contact us" : `$${price}`,
          };
        });
    } catch (e) {
      logError(e, "Failed to fetch pricing plans");
      return [];
    }
  });
};

const planOrder = ["free", "starter", "business", "enterprise"];

const getCTAText = (currentPlan: string, plan: Plan) => {
  if (planOrder.indexOf(currentPlan) < planOrder.indexOf(plan.planName)) {
    return `Upgrade to ${plan.title}`;
  }

  return `Downgrade to ${plan.title}`;
};
