import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

export interface IUserSettings {
  darkMode: boolean;
  engineerMode: boolean;

  // 'Getting started' tasks
  documentationTaskDone: boolean;
}

const initialState: IUserSettings = {
  darkMode: false,
  engineerMode: false,
  documentationTaskDone: false,
};

const userSettingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings(state: IUserSettings, action: PayloadAction<Partial<IUserSettings>>) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setDocumentationTaskDone(state: IUserSettings) {
      state.documentationTaskDone = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  },
});

export const { setSettings, setDocumentationTaskDone } = userSettingsSlice.actions;
export default userSettingsSlice.reducer;
