import { useEffect } from "react";
import {
  Avatar,
  Box,
  Divider,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
  Tooltip,
  Stack,
  Collapse,
  useDisclosure,
} from "@chakra-ui/react";
import {
  CallReceived,
  CallSplit,
  Category,
  ChevronRight,
  Code,
  DeviceHub,
  ArrowUpward,
  LocalLibrary,
  Settings,
  TrackChanges,
  Timeline,
  Extension,
  Payment,
  Input,
  PlayCircleOutline,
} from "@material-ui/icons";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import * as C from "@svix/common/constants";
import Sidebar, { SidebarIcon, SidebarItem } from "@svix/common/widgets/Sidebar";

import { getApiConfiguration } from "src/api";
import { BillingApi, SubscriptionOut } from "src/generated/dashboard-openapi";
import { useIsMemberOrAdmin } from "src/store/selectors";
import { TrackedSidebarItem } from "./TrackedSidebarItem";
import { routeResolver } from "../App";
import { ReactComponent as CoreLogoIcon } from "../img/brand-core-small.svg";
import logo from "../logo.svg";

export const drawerWidth = 240;

interface IDrawerProps {
  mobileDrawerOpen: boolean;
  setMobileDrawerOpen: (isOpen: boolean) => void;
}

export default function Drawer(props: IDrawerProps) {
  const isMemberOrAdmin = useIsMemberOrAdmin();
  const location = useLocation();

  const { data: paymentMethods, isError } = useQuery(
    ["billing", "paymentMethods"],
    async () => {
      const config = await getApiConfiguration();
      const billingApi = new BillingApi(config);
      return billingApi.getPaymentMethodsBillingPaymentMethodsGet();
    }
  );

  const { data: billing } = useQuery<SubscriptionOut, Error>(
    ["billing", "subscription"],
    async () => {
      const config = await getApiConfiguration();
      const billingApi = new BillingApi(config);
      return billingApi.getSubscriptionDetailsBillingSubscriptionGet();
    }
  );

  const missingPayment = !isError && paymentMethods && paymentMethods.data.length === 0;

  const {
    isOpen: isOpenSvixCore,
    onToggle: onToggleSvixCore,
    onOpen: onOpenSvixCore,
  } = useDisclosure({ defaultIsOpen: true });
  const {
    isOpen: isOpenIngest,
    onToggle: onToggleIngest,
    onOpen: onOpenIngest,
  } = useDisclosure({
    defaultIsOpen: location.pathname.startsWith(routeResolver.getRoute("in")),
  });

  useEffect(() => {
    if (location.pathname.startsWith(routeResolver.getRoute("in"))) {
      onOpenIngest();
    } else {
      onOpenSvixCore();
    }
  }, [location.pathname, onOpenIngest, onOpenSvixCore]);

  return (
    <Sidebar {...props}>
      <Flex h="100%" flexDir="column">
        <List w="100%">
          <ListItem
            p={3}
            height="64px"
            display="flex"
            alignItems="center"
            fontWeight={500}
          >
            <Avatar src={logo} size="sm" mr={3} />

            <Heading as="div" size="sm">
              Svix Dashboard
            </Heading>
          </ListItem>
        </List>
        <Divider />
        <List w="100%" p={2}>
          <TrackedSidebarItem
            to={routeResolver.getRoute("getting-started")}
            name="getting-started"
          >
            <SidebarIcon>
              <TrackChanges />
            </SidebarIcon>
            Getting Started
          </TrackedSidebarItem>
        </List>
        <Divider />
        <List w="100%" p={2}>
          <SidebarCollapseItem
            title="Svix Core"
            icon={<CoreLogoIcon width={24} height={24} />}
            isOpen={isOpenSvixCore}
            onClick={onToggleSvixCore}
          />
          <Collapse in={isOpenSvixCore} animateOpacity>
            <Box pl={2}>
              <TrackedSidebarItem name="usage" to={routeResolver.getRoute("usage")}>
                <SidebarIcon>
                  <Timeline />
                </SidebarIcon>
                Usage
              </TrackedSidebarItem>
              <TrackedSidebarItem
                to={routeResolver.getRoute("applications")}
                name="applications"
              >
                <SidebarIcon>
                  <DeviceHub />
                </SidebarIcon>
                Consumer Applications
              </TrackedSidebarItem>
              <TrackedSidebarItem
                to={routeResolver.getRoute("event-types")}
                name="eventtypes"
              >
                <SidebarIcon>
                  <Category />
                </SidebarIcon>
                Event Types
              </TrackedSidebarItem>
              <TrackedSidebarItem
                to={routeResolver.getRoute("connectors")}
                name="connectors"
              >
                <SidebarIcon>
                  <CallSplit />
                </SidebarIcon>
                Connectors
              </TrackedSidebarItem>
              <TrackedSidebarItem
                to={routeResolver.getRoute("integrations")}
                name="integrations"
              >
                <SidebarIcon>
                  <Extension />
                </SidebarIcon>
                Integrations
              </TrackedSidebarItem>
              {isMemberOrAdmin && (
                <TrackedSidebarItem
                  to={routeResolver.getRoute("webhooks")}
                  name="webhooks"
                >
                  <SidebarIcon>
                    <CallReceived />
                  </SidebarIcon>
                  Operational Webhooks
                </TrackedSidebarItem>
              )}
            </Box>
          </Collapse>
        </List>
        <Divider />
        <List w="100%" p={2}>
          <SidebarCollapseItem
            title="Svix Ingest"
            icon={<Input />}
            isOpen={isOpenIngest}
            onClick={onToggleIngest}
          />
          <Collapse in={isOpenIngest}>
            <Box pl={4}>
              <TrackedSidebarItem
                to={routeResolver.getRoute("in.getting-started")}
                name="in-getting-started"
              >
                <SidebarIcon>
                  <PlayCircleOutline />
                </SidebarIcon>
                Start
              </TrackedSidebarItem>
              <TrackedSidebarItem
                to={routeResolver.getRoute("in")}
                name="in-sources"
                matchRoutes={[
                  { path: routeResolver.getRoute("in"), exact: true },
                  { path: routeResolver.getRoute("in.sources"), exact: false },
                ]}
              >
                <SidebarIcon>
                  <Input />
                </SidebarIcon>
                Sources
              </TrackedSidebarItem>
            </Box>
          </Collapse>
        </List>
        <Divider />
        <List w="100%" p={2}>
          <TrackedSidebarItem to={routeResolver.getRoute("api-access")} name="apiaccess">
            <SidebarIcon>
              <Code />
            </SidebarIcon>
            API Access
          </TrackedSidebarItem>
          <TrackedSidebarItem to={routeResolver.getRoute("settings")} name="settings">
            <SidebarIcon>
              <Settings />
            </SidebarIcon>
            Settings
          </TrackedSidebarItem>
          <TrackedSidebarItem to={C.docs.index} name="documentation">
            <SidebarIcon>
              <LocalLibrary />
            </SidebarIcon>
            Documentation
          </TrackedSidebarItem>
        </List>
        <Divider />
        <List w="100%" p={2} pt={8} mt="auto">
          {billing?.planName === "starter" &&
            (missingPayment ? (
              <Tooltip
                label="Add a payment method to increase your account's limits."
                ml={2}
              >
                <Box position="relative">
                  <TrackedSidebarItem
                    to={routeResolver.getRoute("billing")}
                    name="billing-alert"
                    hasBorder
                  >
                    <SidebarIcon color="text.primary">
                      <Payment />
                    </SidebarIcon>
                    <Stack display="inline-flex" spacing={0}>
                      <Text fontWeight="bold" color="text.primary">
                        Add Billing Info
                      </Text>
                      <Text variant="caption" fontSize="sm">
                        To remove Svix limits
                      </Text>
                    </Stack>
                  </TrackedSidebarItem>
                  <Box
                    position="absolute"
                    zIndex={3}
                    right="-4px"
                    top="-4px"
                    bgColor="red.400"
                    height="24px"
                    width="24px"
                    borderRadius="50%"
                    pointerEvents="none"
                  />
                </Box>
              </Tooltip>
            ) : (
              <Tooltip
                label="Upgrade to Professional to get business support, raised limits, and more."
                ml={2}
              >
                <Box>
                  <TrackedSidebarItem
                    to={routeResolver.getRoute("billing.payment._planName", {
                      planName: "business",
                    })}
                    name="billing-upgrade"
                    hasBorder
                  >
                    <SidebarIcon>
                      <ArrowUpward />
                    </SidebarIcon>
                    <Stack display="inline-flex" spacing={0}>
                      <Text>Upgrade plan</Text>
                    </Stack>
                  </TrackedSidebarItem>
                </Box>
              </Tooltip>
            ))}
        </List>
      </Flex>
    </Sidebar>
  );
}

interface ISidebarCollapseItemProps {
  title: string;
  icon: React.ReactNode;
  isOpen: boolean;
  onClick: () => void;
}

function SidebarCollapseItem(props: ISidebarCollapseItemProps) {
  const { title, icon, isOpen, onClick } = props;
  return (
    <SidebarItem onClick={onClick} name={title} to="#">
      <Flex alignItems="center" w="100%">
        <SidebarIcon>{icon}</SidebarIcon>
        {title}
        <ChevronRight
          style={{
            marginLeft: "auto",
            transform: isOpen ? "rotate(90deg)" : "rotate(0deg)",
            transition: "transform 0.3s",
            fontSize: "18px",
          }}
        />
      </Flex>
    </SidebarItem>
  );
}
