import { Fragment } from "react";
import {
  Flex,
  Heading,
  IconButton,
  SkeletonText,
  Thead,
  Tr,
  Th,
  Tbody,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  Divider,
  DrawerCloseButton,
  DrawerBody,
  Stack,
  DrawerFooter,
  Tag,
  Td,
  Table as ChakraTable,
} from "@chakra-ui/react";
import InfoOutlinedIcon from "@material-ui/icons/Info";
import Replay from "@material-ui/icons/Replay";
import capitalize from "lodash/capitalize";

import usePagination from "@svix/common/hooks/pagination";
import { useSetSearch, useSearch } from "@svix/common/hooks/search";
import { formatDateTime } from "@svix/common/utils";
import Code from "@svix/common/widgets/Code";
import IconTooltip from "@svix/common/widgets/IconTooltip";
import Mono from "@svix/common/widgets/Mono";
import Stat from "@svix/common/widgets/Stat";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";
import TimestampTableCell from "@svix/common/widgets/TimestampTableCell";

import { getSvix } from "src/api";
import { IngestLogOut, ListIngestLogOut, SourcesApi } from "src/api/in";
import { useAppSelector } from "src/hooks/store";

export default function SourceErrors({ sourceId }: { sourceId: string }) {
  // const defaultValues = {
  //   before: useInitialDateFilter("before", NOW_FILTER),
  //   after: useInitialDateFilter("after", EARLIEST_DATE_FILTER),
  // };
  // const formCtx = useForm({ defaultValues });

  // let filterCount = 0;

  // FIXME SVIX_IN: Add date filters
  // const beforeDateFilter: DateFilterChoice = useWatch({
  //   control: formCtx.control,
  //   name: "before",
  //   defaultValue: defaultValues.before,
  // });
  // if (beforeDateFilter.value !== NOW_FILTER.value) {
  //   filterCount++;
  // }

  // const afterDateFilter: DateFilterChoice = useWatch({
  //   control: formCtx.control,
  //   name: "after",
  //   defaultValue: defaultValues.after,
  // });
  // if (afterDateFilter.value !== EARLIEST_DATE_FILTER.value) {
  //   filterCount++;
  // }
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;

  const [sourceErrors, sourceErrorsCtx] = usePagination<ListIngestLogOut>(
    ["environments", activeEnvId, "ingest", "sources", sourceId, "source-errors"],
    async (_iterator) => {
      const sv = await getSvix();
      const api = new SourcesApi(sv);
      return await api.getIngestLogs(sourceId, 50, _iterator);
    }
  );

  const openErrorId = useSearch("open-error-id");
  const setSearch = useSetSearch();
  const onCloseDetails = () => setSearch("open-error-id", undefined);

  return (
    <>
      <Flex alignItems="center" mb={3}>
        <IconTooltip
          tooltip="Source errors are messages that were rejected by this source."
          icon={InfoOutlinedIcon}
        >
          <Heading as="h2" size="sm">
            Source Errors
          </Heading>
        </IconTooltip>
        <Flex flexGrow={1} />
        <IconButton
          size="sm"
          aria-label="Refresh"
          variant="toolbar"
          isLoading={sourceErrorsCtx.isFetching}
          onClick={sourceErrorsCtx.refetch}
        >
          <Replay style={{ fontSize: 16 }} />
        </IconButton>
        {/* FIXME SVIX_IN: Add date filters */}
        {/* <FilterMenu control={formCtx.control} filterCount={filterCount} /> */}
      </Flex>

      <Table
        data-cy="sourceerrors-table"
        response={sourceErrors}
        requestElems={sourceErrorsCtx}
        variant="hover"
        emptyState="No errors in this source"
        hasPrevIter
      >
        <Thead>
          <Tr>
            <Th>Timestamp</Th>
            <Th>ID</Th>
            <Th isNumeric>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {sourceErrors?.data.map((sourceError) => (
            <Fragment key={sourceError.id}>
              <SourceErrorRow key={sourceError.id} sourceError={sourceError} />
              <SourceErrorDetails
                sourceError={sourceError}
                isOpen={openErrorId === sourceError.id}
                onClose={onCloseDetails}
              />
            </Fragment>
          ))}
          {sourceErrorsCtx.isLoading && (
            <Tr>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
            </Tr>
          )}
        </Tbody>
      </Table>
    </>
  );
}

function SourceErrorRow({ sourceError }: { sourceError: IngestLogOut }) {
  const setSearch = useSetSearch();
  const onClick = () => setSearch("open-error-id", sourceError.id);

  return (
    <Tr>
      <TimestampTableCell onClick={onClick} ts={new Date(sourceError.created_at)} />
      <TableCell onClick={onClick}>{sourceError.id}</TableCell>
      <TableCell onClick={onClick} isNumeric>
        <Tag size="md">{SourceErrorTypeNames[sourceError.status_code]}</Tag>
      </TableCell>
    </Tr>
  );
}

function SourceErrorDetails({
  sourceError,
  isOpen,
  onClose,
}: {
  sourceError: IngestLogOut;
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="md">
      <DrawerOverlay />
      <DrawerContent borderRadius="lg">
        <DrawerHeader>Source Error</DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody>
          <Stack spacing={2} height="100%">
            <Stat name="Status">{SourceErrorTypeNames[sourceError.status_code]}</Stat>
            <Divider />
            <Stat name="Description">{capitalize(sourceError.error_text)}</Stat>
            <Divider />
            <Stat name="Received at">
              {formatDateTime(new Date(sourceError.created_at))}
            </Stat>
            <Divider />
            <Stat name="Error ID">
              <Mono>{sourceError.id}</Mono>
            </Stat>
            <Divider />
            <Stat name="Headers">
              <ChakraTable variant="striped" size="sm" mb={2}>
                <Tbody>
                  {Object.entries(sourceError.headers).map(([key, value]) => (
                    <Tr key={key}>
                      <Td>
                        <Mono>{key}</Mono>
                      </Td>
                      <Td>
                        <Mono>{value}</Mono>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </ChakraTable>
            </Stat>
            <Divider />
            <Stat name="Payload">
              <Code code={sourceError.payload} copyToClipboard maxH="12em" />
            </Stat>
          </Stack>
        </DrawerBody>
        <DrawerFooter></DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}

// FIXME SVIX_IN: Fill in names
const SourceErrorTypeNames: Record<number, string> = {
  401: "Invalid Signature",
  400: "Invalid Payload",
};
