import { useEffect, Suspense, lazy } from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  Divider,
  TabPanel,
  Text,
  UnorderedList,
  Skeleton,
  Stack,
} from "@chakra-ui/react";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { EventTypeOut } from "svix";

import useCopyToClipboard from "@svix/common/hooks/copyToClipboard";
import Button from "@svix/common/widgets/Button";
import Code from "@svix/common/widgets/Code";
import SelectField from "@svix/common/widgets/form/Select";
import TextField from "@svix/common/widgets/form/TextField";
import Link from "@svix/common/widgets/Link";

import { trackEvent } from "src/analytics";
import { getApiConfiguration, getSvix } from "src/api";
import { routeResolver } from "src/App";
import { OrganizationSettingsApi } from "src/generated/dashboard-openapi";
import { useAppSelector, useAppDispatch } from "src/hooks/store";
import { useRegion } from "src/store/selectors";
import { setDocumentationTaskDone } from "src/store/settings";
import { getSource, SECTIONS } from "./generator";

const LazyMarkDownPreview = lazy(() => import("@uiw/react-markdown-preview"));

interface GeneratorForm {
  displayName: string;
  eventType: string;
}

export function DocumentationTemplate() {
  const region = useRegion();
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const dispatch = useAppDispatch();
  const darkMode = useAppSelector((state) => state.settings.darkMode);

  useEffect(() => {
    dispatch(setDocumentationTaskDone());
  }, [dispatch]);

  const { data: availableEvents } = useQuery(
    ["environments", activeEnvId, "eventTypes"],
    async () => {
      const api = await getSvix();
      return await api.eventType.list({
        limit: 250,
        includeArchived: false,
        withContent: true,
      });
    }
  );

  const { data: orgSettings } = useQuery(
    ["environments", activeEnvId, "orgSettings"],
    async () => {
      const config = await getApiConfiguration();
      const orgSettingsApi = new OrganizationSettingsApi(config);
      return orgSettingsApi.settingsGetOrganizationSettingsGet();
    }
  );
  const isPublished = orgSettings?.eventCatalogPublished ?? false;

  const formCtx = useForm<GeneratorForm>({
    defaultValues: {
      displayName: "",
      eventType: "",
    },
  });
  const { watch } = formCtx;

  const eventCatalogLink = `https://www.svix.com/event-types/${region}/${activeEnvId}/`;
  const selectedEvent = availableEvents?.data.find(
    (eventType) => eventType.name === watch("eventType")
  );
  const hasExample = (selectedEvent as any)?.schemas?.["1"]?.examples?.[0];

  const source = getSource({
    displayName: watch("displayName") || "{Service Name}",
    eventType: selectedEvent || (sampleEvent as any), // FIXME #9420
    eventCatalogLink: isPublished ? eventCatalogLink : undefined,
  });

  const [_, copyToClipboard] = useCopyToClipboard(source);

  const onCopyButtonClick = () => {
    copyToClipboard();
    trackEvent("Webhook Documentation - Copy to clipboard");
  };

  return (
    <Stack>
      <Heading fontSize="lg" mb={2}>
        Generate Webhook Documentation
      </Heading>
      <Text>
        To help you get up and running as fast as possible, we provide a template for your
        webhook documentation to get you started (it's always hard to start writing from a
        blank page). <br />
        Feel free to add/remove sections and make edits after exporting the markdown file.
        This is here to give you a good starting point.
      </Text>

      <Grid templateColumns="repeat(5, 1fr)" gap={6} pt={4}>
        <GridItem w="100%" colSpan={{ sm: 5, md: 1 }}>
          <Stack spacing={8}>
            <TextField
              label="Display Name"
              name="displayName"
              control={formCtx.control}
              placeholder="Your company or service"
            />
            <Box>
              {availableEvents?.data && (
                <SelectField
                  label="Choose example Event Type"
                  name="eventType"
                  control={formCtx.control}
                >
                  <option value="" disabled selected>
                    Select an Event Type
                  </option>
                  {availableEvents.data.map((eventType) => (
                    <option key={eventType.name} value={eventType.name}>
                      {eventType.name}
                    </option>
                  ))}
                </SelectField>
              )}
              {selectedEvent && !hasExample && (
                <Text variant="caption">
                  <ErrorOutlineOutlinedIcon fontSize="inherit" />
                  {
                    " This event type does not have an specified schema example. Try choosing an event type with a example, or "
                  }
                  <Link
                    to={routeResolver.getRoute("event-types._name", {
                      name: selectedEvent.name,
                    })}
                  >
                    configure one
                  </Link>
                  .
                </Text>
              )}
            </Box>
            <Divider />
            <Box>
              <Heading as="h4" size="md" mb={2}>
                Sections
              </Heading>
              <UnorderedList styleType="none" m={0} ml={-2}>
                {SECTIONS.map((section) => (
                  <Box
                    as="li"
                    key={section}
                    _hover={{ backgroundColor: "gray.200" }}
                    borderRadius="md"
                  >
                    <Link
                      color="text.primary"
                      _hover={{ textDecoration: "none" }}
                      href={`#${section.split(" ").join("-").toLowerCase()}`}
                      display="block"
                      p={2}
                      fontSize="md"
                    >
                      {section}
                    </Link>
                  </Box>
                ))}
              </UnorderedList>
            </Box>
          </Stack>
        </GridItem>
        <GridItem w="100%" colSpan={{ sm: 5, md: 4 }}>
          <Stack align="end"></Stack>
          <Tabs variant="solid-rounded" colorScheme="brand" size="sm">
            <TabList>
              <Tab>Preview</Tab>
              <Tab>Raw</Tab>

              <Flex w="100%" justifyContent="end">
                <Button
                  colorScheme="brand"
                  size="sm"
                  variant="outline"
                  leftIcon={<FileCopyOutlinedIcon />}
                  onClick={onCopyButtonClick}
                >
                  Copy to clipboard
                </Button>
              </Flex>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Suspense fallback={<Skeleton h="12em" />}>
                  <LazyMarkDownPreview
                    source={source}
                    style={{ padding: "2rem" }}
                    wrapperElement={{
                      "data-color-mode": darkMode ? "dark" : "light",
                    }}
                  />
                </Suspense>
              </TabPanel>
              <TabPanel>
                <Code language="markdown" copyToClipboard code={source} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </GridItem>
      </Grid>
    </Stack>
  );
}

const sampleEvent: EventTypeOut = {
  createdAt: new Date("2021-08-05T13:00:00Z"),
  updatedAt: new Date("2021-08-05T13:00:00Z"),
  description: "A sample event type",
  name: "hello.world",
  schemas: {
    "1": {
      description: "A sample event",
      examples: [
        {
          type: "hello.world",
          data: {
            hello: "world",
          },
        },
      ],
    },
  },
  deprecated: false,
  archived: false,
  featureFlag: null,
  groupName: null,
};
