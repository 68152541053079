import { useEffect, useState } from "react";
import {
  Box,
  Flex,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  FormLabel,
  ModalBody,
  ModalCloseButton,
  Stack,
  Spinner,
  useToast,
  Text,
  useRadio,
  useRadioGroup,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import * as yup from "yup";

import { setErrors } from "@svix/common/formUtils";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import SelectField from "@svix/common/widgets/form/Select";
import TextAreaField from "@svix/common/widgets/form/TextArea";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getApiConfiguration } from "src/api";
import {
  InvitationApi,
  InviteIn,
  OrganizationAndRole,
  OrganizationRole,
} from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import RoleToggle from "./RoleToggle";
import { trackEvent } from "../../../analytics";

interface IInviteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const schema = yup.object().shape({
  inviteEmails: yup
    .array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue
        ? originalValue.split(/[\s,]+/).filter((s: string) => s.length > 0)
        : [];
    })
    .max(20, "You can only invite up to 20 people at once")
    .of(yup.string().email(({ value }) => `${value} is not a valid email`)),
  overrides: yup.object().shape({}),
});

const defaultValues = {
  inviteEmails: [],
  role: "Viewer" as OrganizationRole,
  overrides: {},
};

interface IInviteForm {
  inviteEmails: string[];
  overrides: Record<string, OrganizationRole | "">;
  role: OrganizationRole;
}

export default function InviteModal(props: IInviteModalProps) {
  const { isOpen, onClose } = props;
  const toast = useToast();
  const queryClient = useQueryClient();

  const formCtx = useForm<IInviteForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { control, reset, formState } = formCtx;

  const [invitingEmail, setInvitingEmail] = useState<string | undefined>();
  const [isGranularAccess, setIsGranularAccess] = useState(false);

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "accessType",
    defaultValue: "all",
    onChange: (val: string) => setIsGranularAccess(val === "granular"),
  });
  const group = getRootProps();

  const envs = useAppSelector((state) => state.auth.environments);

  async function sendInvite(inviteForm: IInviteForm) {
    const config = await getApiConfiguration();
    const inviteApi = new InvitationApi(config);
    let hasErrors = false;

    for (const email of inviteForm.inviteEmails) {
      setInvitingEmail(email);

      const inviteIn: InviteIn = {
        inviteEmail: email.trim(),
        role: inviteForm.role,
      };

      if (isGranularAccess) {
        inviteIn.role = "NoAccess";
        inviteIn.overrides = Object.entries(inviteForm.overrides)
          .map(([envId, role]) => ({ org: envId, role }))
          .filter((o) => o.role !== "NoAccess" && o.role !== "") as OrganizationAndRole[];
      }

      try {
        await inviteApi.createInviteInvitePost(inviteIn);
        queryClient.invalidateQueries(["outgoingInvites", "pending"]);
        trackEvent("Invite User");
        toast({
          duration: 3000,
          title: "Invite sent",
          description: email,
        });
      } catch (e) {
        hasErrors = true;
        if (inviteForm.inviteEmails.length === 1) {
          setErrors(formCtx.setError, e.body);
        } else {
          toast({
            duration: 4000,
            title: `Error inviting ${email}`,
            description: e.body.detail,
            status: "warning",
            variant: "subtle",
          });
        }
      }
    }
    setInvitingEmail(undefined);

    if (!hasErrors) {
      onClose();
    }
  }

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, reset]);

  // Submit form on enter
  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      (e.target as HTMLTextAreaElement).form?.requestSubmit();
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} size="lg">
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        <ModalHeader>Invite your teammate</ModalHeader>
        <Form onSubmit={sendInvite} {...formCtx}>
          <ModalBody>
            <ModalCloseButton />
            <Stack spacing={3}>
              <TextAreaField
                control={control}
                name="inviteEmails"
                label="Email(s)"
                autoFocus
                required
                placeholder="e.g. jane@example.com"
                helperText="You can enter a comma-separated list of emails to invite multiple teammates at once."
                onKeyDown={onKeyDown}
              />
              <GeneralFormErrors />
              <FormLabel>Access type</FormLabel>
              <HStack {...group} pb={1}>
                <RadioCard {...getRadioProps({ value: "all" })}>
                  <Heading as="div" size="xs">
                    All environments
                  </Heading>
                </RadioCard>
                <RadioCard {...getRadioProps({ value: "granular" })}>
                  <Heading as="div" size="xs">
                    Per environment
                  </Heading>
                </RadioCard>
              </HStack>
              {isGranularAccess ? (
                <>
                  <FormLabel>Roles per environment</FormLabel>
                  <TableContainer>
                    <Table variant="simple" size="lg" bgColor="background.secondary">
                      <Thead>
                        <Tr>
                          <Th>Environment</Th>
                          <Th>Role</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {envs.map((env) => (
                          <Tr key={env.orgId}>
                            <Td>
                              <Text fontWeight="medium">{env.orgName}</Text>
                            </Td>
                            <Td>
                              <SelectField
                                control={control}
                                name={`overrides.${env.orgId}`}
                                defaultValue="NoAccess"
                              >
                                <option value="NoAccess">No Access</option>
                                <option value="Viewer">Viewer</option>
                                <option value="Member">Member</option>
                              </SelectField>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                  <Text variant="caption" textAlign="center">
                    By default, the user will not have access to new environments.
                  </Text>
                </>
              ) : (
                <>
                  <RoleToggle control={control} name="role" />
                  <Text variant="caption" textAlign="center">
                    The role will be used as default in all new environments.
                  </Text>
                </>
              )}
            </Stack>
          </ModalBody>
          <ModalFooter>
            {formState.isSubmitting && invitingEmail && (
              <Flex mr={4} alignItems="center" color="text.muted">
                <Spinner size="xs" speed="0.8s" mr={2} />
                <Text>{`Inviting ${invitingEmail}`}</Text>
              </Flex>
            )}
            <SubmitButton isLoading={formState.isSubmitting}>Send Invite</SubmitButton>
          </ModalFooter>
        </Form>
      </ModalContent>
    </Modal>
  );
}

function RadioCard(props: any) {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Box as="label" w="50%">
      <input {...input} />
      <Box
        {...radio}
        cursor="pointer"
        borderWidth="2px"
        borderRadius="md"
        _checked={{
          borderColor: "blue.500",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
        textAlign="center"
      >
        {props.children}
      </Box>
    </Box>
  );
}
