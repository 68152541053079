import { useState } from "react";
import {
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  Collapse,
  ModalHeader,
  ModalFooter,
  Divider,
  ModalBody,
  ModalCloseButton,
  Box,
  Code,
  Text,
  Tooltip,
  Alert,
  AlertIcon,
  useToast,
  Wrap,
  WrapItem,
  Stack,
  useBoolean,
  FormControl,
  FormLabel,
  Switch,
  HStack,
  Flex,
} from "@chakra-ui/react";
import { FileCopy } from "@material-ui/icons";
import { startCase } from "lodash";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { setErrors } from "@svix/common/formUtils";
import useCopyToClipboard from "@svix/common/hooks/copyToClipboard";
import Button from "@svix/common/widgets/Button";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import CheckboxField from "@svix/common/widgets/form/Checkbox";
import TextField from "@svix/common/widgets/form/TextField";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getApiConfiguration } from "src/api";
import { AuthenticationApi, AuthTokenOut } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";

interface CreateKeyModalProps {
  isOpen: boolean;
  onClose: () => void;
  onCreate: () => void;
}

type ScopesMap = {
  [resource: string]: {
    [action: string]: boolean;
  };
};

interface AuthTokenInForm {
  name: string;
  scopes?: ScopesMap;
}

// See https://github.com/svix/svix-webhooks-private/blob/main/server/svix-server/src/core/permissions/scopes.rs
const API_KEY_SCOPES = [
  {
    name: "Applications",
    resource: "application",
    actions: ["Create", "Read", "Update", "Delete"],
  },
  {
    name: "Endpoints",
    resource: "endpoint",
    actions: ["Create", "Read", "Update", "Delete"],
  },
  {
    name: "Event Types",
    resource: "event_type",
    actions: ["Create", "Read", "Update"],
  },
  {
    name: "Integrations",
    resource: "integration",
    actions: ["Create", "Read", "Update", "Delete"],
  },
  {
    name: "Messages",
    resource: "message",
    actions: ["Create", "Read", "DeleteContent"],
  },
  {
    name: "Message Attempts",
    resource: "message_attempt",
    actions: ["Create", "Read", "Update"],
  },
];

const allUnchecked = (scopes: ScopesMap) => {
  return API_KEY_SCOPES.every((scope) => {
    return scope.actions.every((action) => {
      return !scopes[scope.resource][action];
    });
  });
};

const scopesList = (scopes: ScopesMap) => {
  const list: string[] = [];

  Object.entries(scopes).forEach(([resource, actions]) => {
    Object.entries(actions).forEach(([action, enabled]) => {
      if (enabled) {
        list.push(`${resource}:${action}`);
      }
    });
  });

  return list;
};

const DEFAULT_SCOPES = API_KEY_SCOPES.reduce((acc: ScopesMap, scope) => {
  acc[scope.resource] = {};
  scope.actions.forEach((action) => {
    acc[scope.resource][action] = false;
  });
  return acc;
}, {});

export default function CreateKeyModal(props: CreateKeyModalProps) {
  const formCtx = useForm<AuthTokenInForm>({
    defaultValues: {
      name: "",
      scopes: DEFAULT_SCOPES,
    },
  });
  const [restrictScopes, setRestrictScopes] = useBoolean();
  const [newKey, setNewKey] = useState<AuthTokenOut>();
  const [createdKeyScopes, setCreatedKeyScopes] = useState<string[]>();
  const activeEnvId = useAppSelector((state) => state.auth.activeEnvId);
  const queryClient = useQueryClient();
  const toast = useToast();

  const [copied, copyToClipboard] = useCopyToClipboard(newKey?.token);

  const closeModal = () => {
    if (newKey) {
      props.onCreate();
    }
    setNewKey(undefined);
    props.onClose();
  };

  const scopes = formCtx.watch("scopes");
  const hasScopes = scopes && !allUnchecked(scopes);

  async function onCreateKey(form: AuthTokenInForm) {
    const config = await getApiConfiguration();
    const authApi = new AuthenticationApi(config);

    try {
      const keyScopes =
        form.scopes && restrictScopes && !allUnchecked(form.scopes)
          ? scopesList(form.scopes)
          : undefined;
      const key = await authApi.createApiTokenAuthenticationApiTokenPost({
        name: form.name,
        scopes: keyScopes,
      });
      formCtx.reset();
      setNewKey(key);
      setCreatedKeyScopes(keyScopes);
      toast({
        title: "Token created",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      queryClient.removeQueries(["environments", activeEnvId, "authToken"]);
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  }

  const keyName = formCtx.watch("name");

  return (
    <Modal onClose={closeModal} size="2xl" isOpen={props.isOpen}>
      <ModalOverlay />
      <ModalContent borderRadius="lg">
        {!newKey && (
          <>
            <Form onSubmit={onCreateKey} {...formCtx}>
              <ModalHeader>Create an API key</ModalHeader>
              <ModalBody>
                <ModalCloseButton />
                <TextField
                  maxW="40em"
                  control={formCtx.control}
                  name="name"
                  label="Key name"
                  autoFocus
                  required
                />
                <Divider py={2} />
                <FormControl display="flex" alignItems="center" pt={4}>
                  <Switch
                    id="restrictScopes"
                    onChange={() => {
                      setRestrictScopes.toggle();
                      formCtx.reset({ name: keyName });
                    }}
                    isChecked={restrictScopes}
                  />
                  <FormLabel htmlFor="restrictScopes" mb={0} ml={2}>
                    Restrict scopes
                  </FormLabel>
                </FormControl>
                <Collapse in={restrictScopes} animateOpacity>
                  <Text fontSize="md" my={4}>
                    Choose the permissions this API key will have
                  </Text>
                  <Stack spacing={5}>
                    {API_KEY_SCOPES.map((scope) => (
                      <Box key={scope.name}>
                        <Heading size="sm" as="h5" fontWeight="semibold" mb={3}>
                          {scope.name}
                        </Heading>
                        <Wrap spacing={2}>
                          {scope.actions.map((action) => (
                            <WrapItem key={action}>
                              <CheckboxField
                                mr={1}
                                border="1px"
                                borderColor="gray.200"
                                _hover={{ bg: "gray.200", borderColor: "gray.500" }}
                                alignItems="center"
                                borderRadius="md"
                                colorScheme="brand"
                                py={1}
                                px={2}
                                size="sm"
                                name={`scopes.${scope.resource}.${action}`}
                                control={formCtx.control}
                              >
                                <Code
                                  fontWeight="normal"
                                  background="transparent"
                                >{`${startCase(action)}`}</Code>
                              </CheckboxField>
                            </WrapItem>
                          ))}
                        </Wrap>
                      </Box>
                    ))}
                    {!hasScopes && (
                      <Alert variant="plain" status="info" mb={4} borderRadius="md">
                        <AlertIcon color="blue.500" />
                        No scopes selected. Select at least one scope for the API key.
                      </Alert>
                    )}
                  </Stack>
                </Collapse>
              </ModalBody>
              <ModalFooter>
                <Stack>
                  <GeneralFormErrors />
                  <Tooltip
                    isDisabled={!restrictScopes || hasScopes}
                    label="Select at least one scope"
                    hasArrow
                    placement="left"
                  >
                    <Box>
                      <SubmitButton
                        disabled={
                          keyName.length === 0 ||
                          (restrictScopes && !hasScopes) ||
                          formCtx.formState.isSubmitting
                        }
                        isLoading={formCtx.formState.isSubmitting}
                      >
                        Create
                      </SubmitButton>
                    </Box>
                  </Tooltip>
                </Stack>
              </ModalFooter>
            </Form>
          </>
        )}
        {newKey && (
          <>
            <ModalHeader>API key created</ModalHeader>
            <ModalBody>
              <ModalCloseButton />
              <Alert status="info" mb={4}>
                <AlertIcon />
                You will not be able to reveal this key again, so please make sure to take
                note of it now.
              </Alert>
              <Stack spacing={2}>
                <Text>
                  <Text fontWeight="semibold" display="inline">
                    Name:
                  </Text>{" "}
                  {newKey.name}
                </Text>
                {createdKeyScopes && (
                  <Text>
                    <Text fontWeight="semibold" display="inline">
                      Scopes:
                    </Text>{" "}
                    <Code>{createdKeyScopes.join(", ")}</Code>
                  </Text>
                )}
                <HStack>
                  <Text fontWeight="semibold" display="inline">
                    Key:
                  </Text>{" "}
                  <Tooltip
                    label={copied ? "Copied" : "Click to copy"}
                    hasArrow
                    closeOnClick={false}
                  >
                    <Flex alignItems="center" cursor="pointer" onClick={copyToClipboard}>
                      <Code>{newKey.token}</Code>{" "}
                      <FileCopy style={{ marginLeft: "0.4rem" }} />
                    </Flex>
                  </Tooltip>
                </HStack>
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" onClick={closeModal}>
                Close
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
