import { CallReceived } from "@material-ui/icons";
import { useQuery } from "react-query";
import { Svix, ListResponseEndpointOut } from "svix";

import * as C from "@svix/common/constants";
import Link from "@svix/common/widgets/Link";

import { getRegion, getSvix } from "src/api";
import { SourceOut, SourcesApi } from "src/api/in";
import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import GettingStartedCard from "src/screens/GettingStarted/GettingStartedCard";

export default function SetupDestinations({
  createdSource,
}: {
  createdSource?: SourceOut;
}) {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;

  const { data: endpoints } = useQuery<ListResponseEndpointOut>(
    ["environments", activeEnvId, "ingest", "sources", createdSource?.id, "endpoints"],
    async () => {
      const sv = await getSvix();
      const api = new SourcesApi(sv);
      const { token } = await api.getDashboard(createdSource!.id);
      // FIXME SVIX_IN: This is a hacky way of doing this.
      const appPortalSvix = new Svix(token, {
        serverUrl: C.envConfig.getServerUrl(getRegion()),
      });
      return appPortalSvix.endpoint.list(`app_${createdSource?.id}`);
    },
    {
      enabled: !!createdSource,
      refetchInterval: (res) => (res?.data && res.data.length > 0 ? false : 2000),
    }
  );
  const done = endpoints?.data && endpoints.data.length > 0;

  return (
    <GettingStartedCard
      title="Set up destinations for your Source"
      icon={<CallReceived />}
      done={done}
      cta={
        createdSource && (
          <Link
            to={`${routeResolver.getRoute("in.sources._id", {
              sourceId: createdSource.id,
            })}?selected-tab=destinations`}
          >
            Source destinations
          </Link>
        )
      }
    >
      In the Source details page, set up a new endpoint to receive events.
    </GettingStartedCard>
  );
}
