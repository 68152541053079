import { useState } from "react";
import { HStack, Stack, Box, Divider } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Steps, Step, useSteps } from "chakra-ui-steps";
import { useForm, UseFormReturn } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import * as yup from "yup";

import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { IngestSourceIn, SourceOut, SourcesApi } from "src/api/in";
import { routeResolver } from "src/App";
import {
  sourceAuthConfigFromForm,
  SourceConfigurationForm,
} from "../Source/SourceConfiguration";
import SourceUrlCard from "../Source/SourceUrlCard";

interface SourceForm extends SourceConfigurationForm {
  name: string;
}

const defaultValues: SourceForm = {
  name: "",
  type: "genericWebhook",
  secret: "",
  enableAuth: false,
};

const schema = yup.object().shape({
  name: yup.string().trim().min(3, "Name must be at least 3 characters"),
});

export default function SourceCreateScreen() {
  const history = useHistory();
  const formCtx = useForm<SourceForm>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [createdSource, setCreatedSource] = useState<SourceOut | undefined>(undefined);

  const { nextStep, activeStep } = useSteps({
    initialStep: 0,
  });

  async function onAddSource(form: SourceForm) {
    const sv = await getSvix();
    const api = new SourcesApi(sv);
    const newSource = await api.create({
      name: form.name,
      type: "genericWebhook",
    });
    setCreatedSource(newSource);
    nextStep();
  }

  async function onSubmit(form: SourceConfigurationForm) {
    const sv = await getSvix();
    const api = new SourcesApi(sv);

    const updatedSource = {
      ...createdSource!,
      type: form.type,
      config: sourceAuthConfigFromForm(form),
    } as IngestSourceIn;

    await api.update(createdSource!.id, updatedSource);
    history.push(
      routeResolver.getRoute("in.sources._id", { sourceId: createdSource!.id })
    );
  }

  const Step1 = (
    <Box maxW="30em">
      <Form onSubmit={onAddSource} {...formCtx}>
        <GeneralFormErrors />
        <Stack spacing={4}>
          <TextField
            control={formCtx.control}
            name="name"
            label="Name"
            autoFocus
            required
            placeholder="e.g. github-prod"
          />
          <Divider />
          <HStack spacing={4} justifyContent="flex-end">
            <Button
              colorScheme="gray"
              as={Link}
              to={routeResolver.getRoute("in.sources")}
            >
              Cancel
            </Button>
            <SubmitButton isLoading={formCtx.formState.isSubmitting}>Next</SubmitButton>
          </HStack>
        </Stack>
      </Form>
    </Box>
  );

  const Step2 = (
    <Stack spacing={4}>
      {createdSource && <SourceUrlCard source={createdSource} showRotateButton />}
      <Card maxW="45em">
        <SourceConfigurationForm
          formCtx={formCtx as unknown as UseFormReturn<SourceConfigurationForm>}
          showActions
          showCancel={false}
          onSubmit={onSubmit}
          cancelLabel="Configure later"
        />
      </Card>
    </Stack>
  );

  return (
    <>
      <MetaTitle path={["New Source"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("in.sources")}>
            Sources
          </BreadcrumbItem>
          <BreadcrumbItem>New Source</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>

      <Box maxW="70em">
        <Box maxW="30em">
          <Steps activeStep={activeStep} mb={6}>
            <Step label="Step 1" description="Create source" />
            <Step label="Step 2" description="Configure authentication" />
          </Steps>
        </Box>
        {activeStep === 0 && Step1}
        {activeStep === 1 && Step2}
      </Box>
    </>
  );
}
