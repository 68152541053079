import { useState } from "react";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  AlertTitle,
  Box,
  Button as ChakraButton,
  Thead,
  Tbody,
  Tr,
  Th,
  Code,
  Heading,
  Icon,
  Link,
  SkeletonText,
  Text,
  MenuItem,
  Td,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
} from "@chakra-ui/react";
import { Lock } from "@material-ui/icons";
import VpnKeyIcon from "@material-ui/icons/VpnKeyOutlined";

import * as C from "@svix/common/constants";
import usePagination from "@svix/common/hooks/pagination";
import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import Mono from "@svix/common/widgets/Mono";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";
import TableRowMenu from "@svix/common/widgets/TableRowMenu";
import TimestampTableCell from "@svix/common/widgets/TimestampTableCell";

import { getApiConfiguration } from "src/api";
import { AuthenticationApi, AuthTokenCensoredOut } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import { useRegion } from "src/store/selectors";
import CreateKeyModal from "./CreateKeyModal";
import EditKeyModal from "./EditKeyModal";
import ExpireTokenModal from "./ExpireTokenModal";

export default function ApiAccess() {
  const region = useRegion();

  return (
    <>
      <MetaTitle path={["API Access"]} />
      <Heading as="h1" mt={2} size="lg" mb={8}>
        API Access
      </Heading>
      <Box>
        <Card borderRadius="2xl">
          <Box alignItems="flex-start" display="flex" p={2}>
            <Box
              display="inline-block"
              bg="background.modifier.border"
              color="white"
              p="3"
              borderRadius="full"
              lineHeight="0"
            >
              <Icon boxSize={8} as={Lock} />
            </Box>
            <Box flexGrow={1} mx={6}>
              <Heading as="h2" size="sm" mb={4}>
                Manage your API keys
              </Heading>
              <Text mb={6}>
                These keys allow you to authenticate with the{" "}
                <Link fontWeight="semibold" href={C.docs.apiRef} isExternal>
                  Svix API
                </Link>{" "}
                for the currently active environment at the following server:{" "}
                <Code>{C.envConfig.getServerUrl(region)}</Code>.
              </Text>
              <KeyTable active />
            </Box>
          </Box>
        </Card>
      </Box>
      <Box py={6}>
        <Alert status="info" borderRadius="2xl" variant="plain">
          <AlertIcon color="blue.500" />
          <Box>
            <AlertTitle>Keep your keys safe</AlertTitle>
            <AlertDescription>
              <Text>
                Your API keys are sensitive. Keep them safe and avoid sharing them
                anywhere public.
              </Text>
              <Text>
                In the event that an API token is compromised, you can rotate it to
                generate a new key and deactivate the old one.
              </Text>
            </AlertDescription>
          </Box>
        </Alert>
      </Box>
    </>
  );
}

interface KeyTableProps {
  active?: boolean;
}

export type Key = AuthTokenCensoredOut;

function KeyTable(props: KeyTableProps) {
  const activeEnvId = useAppSelector((state) => state.auth.activeEnvId)!;
  const [createKeyModalOpen, setCreateKeyModalOpen] = useState(false);
  const [editKey, setEditKey] = useState<Key>();
  const [rotateKey, setRotateKey] = useState<Key>();

  const [authTokens, authTokensCtx] = usePagination(
    ["environments", activeEnvId, "authTokens"],
    async (iterator) => {
      const config = await getApiConfiguration();
      const authApi = new AuthenticationApi(config);
      return authApi.listApiTokensAuthenticationApiTokenGet(
        iterator as string | undefined
      );
    }
  );

  const ApiKeysEmptyState = () => {
    return <Text>No API keys</Text>;
  };

  return (
    <>
      {props.active && (
        <Button mt={4} onClick={() => setCreateKeyModalOpen(true)}>
          Create key
        </Button>
      )}
      <Box my={4}>
        <Table
          response={authTokens}
          requestElems={authTokensCtx}
          variant="simple"
          emptyState={<ApiKeysEmptyState />}
        >
          <Thead>
            <Tr padding="5em">
              <Th>Name</Th>
              <Th>Key</Th>
              <Th>Scopes</Th>
              <Th>Created at</Th>
              <Th>Expires at</Th>
              <Th width={1}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {authTokensCtx.isLoading && (
              <Tr>
                <TableCell>
                  <SkeletonText noOfLines={1} />
                </TableCell>
                <TableCell>
                  <SkeletonText noOfLines={1} />
                </TableCell>
                <TableCell>
                  <SkeletonText noOfLines={1} />
                </TableCell>
                <TableCell>
                  <SkeletonText noOfLines={1} />
                </TableCell>
              </Tr>
            )}
            {!authTokensCtx.isLoading &&
              authTokens?.data.map((key, i) => (
                <Tr key={i}>
                  <TableCell>{key.name ?? "<empty>"}</TableCell>
                  <TableCell>
                    <Code>{key.censoredToken}</Code>
                  </TableCell>
                  <KeyScopesCell k={key} />
                  <TimestampTableCell ts={key.createdAt} />
                  <TimestampTableCell ts={key.expiresAt} />
                  {props.active && (
                    <TableRowMenu>
                      <MenuItem onClick={() => setEditKey(key)}>Edit</MenuItem>
                      <MenuItem onClick={() => setRotateKey(key)}>
                        Expire API Key
                      </MenuItem>
                    </TableRowMenu>
                  )}
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
      <CreateKeyModal
        key={`create-${createKeyModalOpen}`}
        isOpen={createKeyModalOpen}
        onClose={() => setCreateKeyModalOpen(false)}
        onCreate={authTokensCtx.refetch}
      />
      <EditKeyModal
        key={`edit-${editKey?.id}`}
        isOpen={!!editKey}
        apiKey={editKey}
        onClose={() => setEditKey(undefined)}
        onSave={authTokensCtx.refetch}
      />
      <ExpireTokenModal
        key={`rotate-${rotateKey?.id}`}
        isOpen={!!rotateKey}
        apiKey={rotateKey}
        onClose={() => setRotateKey(undefined)}
        onRotate={authTokensCtx.refetch}
      />
    </>
  );
}

function KeyScopesCell(props: { k: AuthTokenCensoredOut }) {
  const { k } = props;

  return (
    <Td>
      <Popover placement="bottom">
        <PopoverTrigger>
          <ChakraButton size="xs" variant="outline" fontSize="sm" w="100%" opacity={0.8}>
            <VpnKeyIcon style={{ height: "0.7em", width: "0.7em" }} />
            <Mono ml={2}>{k.scopes ? k.scopes.length : "*"}</Mono>
          </ChakraButton>
        </PopoverTrigger>
        <PopoverContent textAlign="left">
          <PopoverArrow />
          {k.scopes ? (
            <>
              <PopoverHeader>
                <Heading p={2} as="h6" size="sm">
                  API key scopes
                </Heading>
              </PopoverHeader>
              <PopoverBody>
                {k.scopes.map((scope) => (
                  <Box key={scope} p={1}>
                    <Code>{scope}</Code>
                  </Box>
                ))}
              </PopoverBody>
            </>
          ) : (
            <>
              <PopoverHeader>
                <Heading p={2} as="h6" size="sm">
                  No scopes
                </Heading>
              </PopoverHeader>
              <PopoverBody>This API key has full access to all resources.</PopoverBody>
            </>
          )}
        </PopoverContent>
      </Popover>
    </Td>
  );
}
