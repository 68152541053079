import { Box, ButtonGroup, Text, Tooltip } from "@chakra-ui/react";
import { Info } from "@material-ui/icons";
import { EventTypeIn, EventTypeOut } from "svix";
import ReadOnlyTooltip from "svix-frontend/src/widgets/ReadOnlyTooltip";

import useUpdateMutation from "@svix/common/hooks/mutate";
import Button from "@svix/common/widgets/Button";
import Form from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import Stat from "@svix/common/widgets/Stat";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { useAppSelector } from "src/hooks/store";
import { useIsMemberOrAdmin } from "src/store/selectors";

interface IGroupNameProps {
  eventType: EventTypeOut;
}

export function GroupName(props: IGroupNameProps) {
  const { eventType } = props;
  const isMemberOrAdmin = useIsMemberOrAdmin();
  return (
    <GroupNameInner
      eventType={eventType}
      isMemberOrAdmin={isMemberOrAdmin}
      handleMutate={async (data) => {
        const api = await getSvix();
        return api.eventType.update(eventType.name, data);
      }}
    />
  );
}

type InnerProps = IGroupNameProps & {
  isMemberOrAdmin: boolean;
  handleMutate: (data: EventTypeIn) => Promise<EventTypeOut>;
};

export function GroupNameInner(props: InnerProps) {
  const { eventType, isMemberOrAdmin } = props;
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const isReadOnly = !isMemberOrAdmin;
  const queryKey = ["environments", activeEnvId, "eventTypes", eventType.name];

  const { edit, cancelEdit, formCtx, mutate, isEditing, isSubmitting } =
    useUpdateMutation<EventTypeOut>(queryKey, eventType, "groupName", async (form) => {
      const data = {
        ...form,
        groupName: form.groupName?.trim() || undefined,
      };
      return props.handleMutate(data as EventTypeIn);
    });

  return (
    <Form onSubmit={mutate} {...formCtx}>
      <Stat
        name={
          <Tooltip label="The App Portal UI will logically group event types based on their name. You can override how they are grouped by setting a custom group name.">
            <Box display="flex" alignItems="center">
              Group name
              <Info
                style={{
                  marginLeft: "0.4rem",
                  fontSize: "1rem",
                  opacity: 0.3,
                }}
              />
            </Box>
          </Tooltip>
        }
        cta={
          isEditing ? (
            <ButtonGroup ml={2} size="xs">
              <Button colorScheme="gray" onClick={cancelEdit}>
                Cancel
              </Button>
              <SubmitButton key="save" isLoading={isSubmitting}>
                Save
              </SubmitButton>
            </ButtonGroup>
          ) : (
            <ReadOnlyTooltip readOnly={isReadOnly}>
              <Button
                isDisabled={isReadOnly}
                type="button"
                size="xs"
                colorScheme="gray"
                ml={2}
                onClick={edit}
                key="edit"
              >
                Edit
              </Button>
            </ReadOnlyTooltip>
          )
        }
      >
        {isEditing ? (
          <TextField
            autoFocus
            name="groupName"
            aria-label="groupName"
            control={formCtx.control}
          />
        ) : (
          <>
            {eventType.groupName ? (
              <Text data-testid="ff-display" whiteSpace="pre-wrap">
                {eventType.groupName}
              </Text>
            ) : (
              <Text data-testid="ff-display" variant="caption">
                No custom group
              </Text>
            )}
          </>
        )}
      </Stat>
    </Form>
  );
}
