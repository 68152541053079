import { useCallback, useEffect, useState } from "react";
import { Code, Link as StyledLink, Stack, Text } from "@chakra-ui/react";
import { useForm, FieldError } from "react-hook-form";
import { EventTypeApi, EventTypeFromOpenApi } from "svix/dist/openapi";

import * as C from "@svix/common/constants";
import { getApiError } from "@svix/common/utils";
import Form from "@svix/common/widgets/Form";
import FileInput from "@svix/common/widgets/form/FileInput";

import { getSvix } from "src/api";

interface EventTypesUploadProps {
  onUpload: (eventTypes: EventTypeFromOpenApi[]) => void;
}

interface FileUploadForm {
  file: any; // File type contains a recursive TS definition that does not work with react-hook-form
}

export default function EventTypesUpload(props: EventTypesUploadProps) {
  const { onUpload } = props;
  const [isUploading, setIsUploading] = useState(false);
  const formCtx = useForm<FileUploadForm>({ defaultValues: { file: null } });
  const { formState } = formCtx;

  const file = formCtx.watch("file");

  const handleFileChange = useCallback(() => {
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      if (!e.target || e.target.result === null) {
        return;
      }
      const data = e.target.result;
      try {
        const svix = await getSvix();
        const api = new EventTypeApi(svix._configuration);
        const res = await api.v1EventTypeImportOpenapi({
          eventTypeImportOpenApiIn: {
            specRaw: data as string,
            dryRun: true,
          },
        });
        const eventTypes = res.data.toModify || [];

        if (eventTypes.length === 0) {
          formCtx.setError("file", {
            message:
              "No event types found in the OpenAPI spec. Make sure the spec has a 'webhooks' or 'x-webhooks' field.",
          });
          return;
        }

        onUpload(eventTypes);
      } catch (err) {
        formCtx.setError("file", {
          message: getApiError(
            err,
            "An error occurred while uploading the OpenAPI spec."
          ),
        });
      } finally {
        setIsUploading(false);
      }
    };

    setIsUploading(true);
    reader.readAsText(file[0]);
  }, [formCtx, file, onUpload]);

  useEffect(() => {
    handleFileChange();
  }, [file, handleFileChange]);

  return (
    <Stack maxW="60em">
      <Text>
        Upload the OpenAPI specification for your API. If it has a <Code>webhooks</Code>{" "}
        or <Code>x-webhooks</Code> field, it can be used to easily create Svix Event Types
        that match your API spec.{" "}
        <StyledLink color="blue.500" href={C.docs.eventTypes.openapiImport}>
          Learn more in our docs.
        </StyledLink>
      </Text>
      <Form {...formCtx} onSubmit={handleFileChange}>
        <FileInput
          accept="application/JSON,.yml,.yaml"
          name="file"
          prompt="Upload OpenAPI specification"
          height="30em"
          isLoading={isUploading}
          isDisabled={isUploading}
        />
      </Form>
      <Text color="text.danger">
        {formState.errors.file && (formState.errors.file as FieldError).message}
      </Text>
    </Stack>
  );
}
