import { Flex, Icon } from "@chakra-ui/react";

import RadioButtons from "@svix/common/widgets/form/RadioButtons";

import { regions } from "src/constants";
import { OrganizationRegion } from "src/generated/dashboard-openapi/models/OrganizationRegion";

interface IEnvTagToggleProps {
  isDisabled?: boolean;
  control: any;
  name: string;
}

export default function EnvTagRadio(props: IEnvTagToggleProps) {
  const buttons = Object.freeze([
    {
      title: (
        <Flex alignItems="center">
          <Icon boxSize={8} as={regions.eu.flag} mr={4} />
          {regions.eu.shortLabel}
        </Flex>
      ),
      description: "",
      value: "eu" as OrganizationRegion,
    },
    {
      title: (
        <Flex alignItems="center">
          <Icon boxSize={8} as={regions.us.flag} mr={4} />
          {regions.us.shortLabel}
        </Flex>
      ),
      description: "",
      value: "us" as OrganizationRegion,
    },
  ]);

  return (
    <RadioButtons<OrganizationRegion>
      label="Region"
      buttons={buttons}
      direction="row"
      {...props}
    />
  );
}
