import {
  Box,
  Grid,
  HStack,
  Flex,
  Stack,
  SkeletonText,
  Skeleton,
  Tabs,
  TabList,
  Tab,
  Text,
  TabPanels,
  TabPanel,
  useBoolean,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  GridItem,
} from "@chakra-ui/react";
import { MoreVert } from "@material-ui/icons";
import { useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { AppPortal } from "svix-react";

import { useSetSearch, useSearch } from "@svix/common/hooks/search";
import Card from "@svix/common/widgets/Card";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import Link from "@svix/common/widgets/Link";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import ResourceNotFound from "@svix/common/widgets/ResourceNotFound";

import { getSvix } from "src/api";
import { ListIngestLogOut, SourceOut, SourcesApi } from "src/api/in";
import { routeResolver } from "src/App";
import { DashboardAccessOut } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import SourceConfiguration from "./SourceConfiguration";
import SourceErrors from "./SourceErrors";
import SourceName from "./SourceName";
import SourceUrlCard from "./SourceUrlCard";

const tabs = ["overview", "destinations", "source-errors"];

export default function Source() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const darkMode = useAppSelector((state) => state.settings.darkMode);
  const { sourceId } = useParams<{ sourceId: string }>();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useBoolean();
  const toast = useToast();
  const history = useHistory();

  const { data: sourceAppPortal } = useQuery<DashboardAccessOut>(
    ["environments", activeEnvId, "ingest", "sources", sourceId, "app_portal"],
    async () => {
      const sv = await getSvix();
      const api = new SourcesApi(sv);
      return api.getDashboard(sourceId);
    }
  );

  const {
    data: sourceData,
    isLoading,
    error,
  } = useQuery<SourceOut>(
    ["environments", activeEnvId, "ingest", "sources", sourceId],
    async () => {
      const sv = await getSvix();
      const api = new SourcesApi(sv);
      return api.get(sourceId);
    }
  );

  const { data: sourceErrorsData } = useQuery<ListIngestLogOut>(
    ["environments", activeEnvId, "ingest", "sources", sourceId, "source-errors"],
    async () => {
      const sv = await getSvix();
      const api = new SourcesApi(sv);
      // FIXME SVIX_IN: Should query by date range
      return await api.getIngestLogs(sourceId, 100);
    }
  );
  const oneDayAgo = new Date(Date.now() - 24 * 60 * 60 * 1000);
  const sourceErrors = sourceErrorsData?.data.filter(
    (e) => new Date(e.created_at) >= oneDayAgo
  );
  const hasSourceErrors = sourceErrors && sourceErrors.length > 0;

  const onDeleteSource = async () => {
    try {
      const sv = await getSvix();
      const api = new SourcesApi(sv);
      await api.delete(sourceId);
      setDeleteDialogOpen.off();
      toast({
        title: "Source deleted",
        status: "success",
      });
      history.push(routeResolver.getRoute("in.sources"));
    } catch (e) {
      toast({
        title: "Failed to delete source",
        description: e.body.message,
        status: "error",
      });
    }
  };

  const tab = useSearch("selected-tab");
  const setSearch = useSetSearch();
  const maybeTabIndex = tabs.indexOf(tab ?? "overview");
  const tabsIndex = maybeTabIndex === -1 ? 0 : maybeTabIndex;

  if (error) {
    return (
      <ResourceNotFound resourceName="source" to={routeResolver.getRoute("in.sources")} />
    );
  }

  return (
    <>
      <MetaTitle path={["Source", sourceId]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("in.sources")}>
            Sources
          </BreadcrumbItem>
          <Skeleton w="8em" isLoaded={!!sourceData?.name} display="inline-block">
            <BreadcrumbItem>{sourceData?.name}</BreadcrumbItem>
          </Skeleton>
        </Breadcrumbs>
        <Flex flexGrow={1} />
        <Box>
          <Menu placement="bottom-end">
            <MenuButton as={IconButton} variant="rounded">
              <MoreVert />
            </MenuButton>
            <MenuList>
              <MenuItem textColor="text.danger" onClick={setDeleteDialogOpen.on}>
                Delete source
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </PageToolbar>
      {isLoading && <SourceSkeleton />}
      {sourceData && (
        <>
          <Stack spacing={4}>
            <Box>
              <SourceName source={sourceData} />
            </Box>
            <Tabs
              variant="enclosed"
              index={tabsIndex}
              onChange={(i) => setSearch("selected-tab", tabs[i])}
            >
              <TabList>
                <Tab name="overview">Overview</Tab>
                <Tab name="destinations">Destinations</Tab>
                <Tab name="source-errors">Source Errors</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Grid templateColumns={{ base: "1fr", xl: "1fr 1fr" }} gap={4}>
                    <GridItem colSpan={2}>
                      <SourceUrlCard source={sourceData} showRotateButton />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <SourceConfiguration source={sourceData} />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Card>
                        <Text fontSize="sm" fontWeight="bold">
                          Source errors • Last 24 hours
                        </Text>
                        <Text fontSize="sm">
                          {hasSourceErrors ? (
                            <Link
                              onClick={() => setSearch("selected-tab", "source-errors")}
                            >{`${sourceErrors.length} recent errors`}</Link>
                          ) : (
                            "No errors in the last 24 hours."
                          )}
                        </Text>
                      </Card>
                    </GridItem>
                  </Grid>
                </TabPanel>
                <TabPanel>
                  <AppPortal
                    url={sourceAppPortal && getAppPortalUrl(sourceAppPortal)}
                    fullSize
                    darkMode={darkMode}
                    style={{ minHeight: "450px" }}
                  />
                </TabPanel>
                <TabPanel>
                  <SourceErrors sourceId={sourceId} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>

          <ConfirmationDialog
            title={`Delete ${sourceData.name}`}
            isOpen={isDeleteDialogOpen}
            onCancel={setDeleteDialogOpen.off}
            onOk={onDeleteSource}
            labelOk="Delete"
          >
            Are you sure you would like to delete this source? The Ingest URL will stop
            accepting requests.
          </ConfirmationDialog>
        </>
      )}
    </>
  );
}

const getAppPortalUrl = (portal: DashboardAccessOut) => {
  const url = new URL(portal.url);
  url.searchParams.set("noGutters", "true");
  url.searchParams.set("_svixHideEventTypes", "true");
  return url.toString();
};

function SourceSkeleton() {
  return (
    <Stack spacing={4}>
      <Skeleton height={8} w="16em" />
      <Skeleton height={8} w="100%" />
      <HStack spacing={4}>
        <Skeleton height={24} w="50%" />
        <SkeletonText height={24} w="50%" />
      </HStack>
      <HStack spacing={4}>
        <Skeleton height={48} w="50%" />
        <Box w="50%" />
      </HStack>
    </Stack>
  );
}
