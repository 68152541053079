import {
  Box,
  FormControl,
  Stack,
  useRadio,
  useRadioGroup,
  RadioProps,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";

import { PaymentMethodOut } from "src/generated/dashboard-openapi";
import CreditCardIcon from "./CreditCardIcon";

function RadioField({ children, name, ...inputProps }: RadioProps) {
  const { getInputProps, getRadioProps } = useRadio(inputProps);
  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...radio}
        borderWidth={2}
        cursor="pointer"
        _checked={{
          background: "blue.50",
          borderColor: "interactive.accent",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        _hover={{
          background: "blue.50",
          borderColor: "interactive.accent",
        }}
        border="1px solid"
        borderColor="background.modifier.border"
        borderRadius="lg"
        fontWeight="semibold"
        letterSpacing="0.25px"
        px={6}
        py={4}
        transition="0.2s border-color ease-in"
      >
        {children}
      </Box>
    </Box>
  );
}

interface ICardSelectProps {
  paymentMethods: PaymentMethodOut[];
  control: any;
  name: string;
}

export default function CardSelect({ control, name, paymentMethods }: ICardSelectProps) {
  const {
    field: { ref, ...controlProps },
  } = useController({
    name,
    control,
    defaultValue: "Member",
    rules: { required: true },
  });
  const { getRootProps, getRadioProps } = useRadioGroup(controlProps);

  const group = getRootProps();

  return (
    <FormControl id={name}>
      <Stack spacing={4} maxW="24em" {...group}>
        {paymentMethods.map((paymentMethod) => (
          <RadioField
            key={paymentMethod.id}
            {...getRadioProps({ value: paymentMethod.id })}
          >
            <CreditCardIcon brand={paymentMethod.cardBrand} />
            •••• •••• •••• {paymentMethod.last4}
          </RadioField>
        ))}
        <RadioField {...getRadioProps({ value: "new" })}>
          Add a new payment method
        </RadioField>
      </Stack>
    </FormControl>
  );
}
