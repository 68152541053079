import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Text,
  Heading,
  Stack,
  useRadio,
  useRadioGroup,
  RadioProps,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";

import { UpdateRoleModalSelection } from "./UpdateRoleModal";

function RadioField({ children, name, ...inputProps }: RadioProps) {
  const { getInputProps, getRadioProps } = useRadio(inputProps);
  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Box as="label">
      <input {...input} />
      <Box
        {...radio}
        cursor="pointer"
        borderWidth="2px"
        borderRadius="md"
        _checked={{
          borderColor: "blue.500",
        }}
        _focus={{
          boxShadow: "outline",
        }}
        px={5}
        py={3}
      >
        {children}
      </Box>
    </Box>
  );
}

interface IRoleToggleProps {
  control: any;
  name: string;
  hideLabel?: boolean;
  disabled?: boolean;
  showRoles?: UpdateRoleModalSelection[];
}

export default function RoleToggle({
  control,
  name,
  hideLabel,
  disabled,
  showRoles: propShowRoles,
}: IRoleToggleProps) {
  const {
    field: { ref, ...controlProps },
  } = useController({
    name,
    control,
    defaultValue: "Viewer",
    rules: { required: true },
  });
  const { getRootProps, getRadioProps } = useRadioGroup(controlProps);
  const showRoles: UpdateRoleModalSelection[] = propShowRoles ?? [
    "Viewer",
    "Member",
    "Admin",
  ];

  const group = getRootProps();

  return (
    <FormControl id={name}>
      {!hideLabel && <FormLabel>Role</FormLabel>}
      <Stack {...group}>
        {showRoles.indexOf("Default") !== -1 && (
          <>
            <RadioField
              key="Default"
              {...getRadioProps({ value: "Default" })}
              isDisabled={disabled}
            >
              <Heading as="div" size="sm">
                Default
              </Heading>
              <Text variant="caption">
                The user's role in this environment will be the default user role in the
                organization.
              </Text>
            </RadioField>
            <Box py={1} px="30%">
              <Divider />
            </Box>
          </>
        )}

        {showRoles.indexOf("NoAccess") !== -1 && (
          <>
            <RadioField
              key="NoAccess"
              {...getRadioProps({ value: "NoAccess" })}
              isDisabled={disabled}
            >
              <Heading as="div" size="sm">
                No Access
              </Heading>
              <Text variant="caption">
                The user has no access to the environment. They will not be able to view
                it
              </Text>
            </RadioField>
            <Box py={1} px="30%">
              <Divider />
            </Box>
          </>
        )}

        {showRoles.indexOf("Viewer") !== -1 && (
          <RadioField
            key="Viewer"
            {...getRadioProps({ value: "Viewer" })}
            isDisabled={disabled}
          >
            <Heading as="div" size="sm">
              Viewer
            </Heading>
            <Text variant="caption">
              A viewer has basic privileges and can view statistics and basic
              configurations but can't access API keys.
            </Text>
          </RadioField>
        )}
        {showRoles.indexOf("Member") !== -1 && (
          <RadioField
            key="Member"
            {...getRadioProps({ value: "Member" })}
            isDisabled={disabled}
          >
            <Heading as="div" size="sm">
              Member
            </Heading>
            <Text variant="caption">
              A member has basic privileges and can manage some settings in addition to
              having access to API keys.
            </Text>
          </RadioField>
        )}
        {showRoles.indexOf("Admin") !== -1 && (
          <RadioField
            key="Admin"
            {...getRadioProps({ value: "Admin" })}
            isDisabled={disabled}
          >
            <Heading as="div" size="sm">
              Admin
            </Heading>
            <Text variant="caption">
              An organization admin can add and remove members to the organization.
            </Text>
          </RadioField>
        )}
      </Stack>
    </FormControl>
  );
}

export const ROLE_DESCRIPTION = {
  Default:
    "The user's role in this environment will be the default user role in the organization.",
  NoAccess: "The user has no access to the environment. They will not be able to view it",
  Viewer:
    "A viewer has basic privileges and can view statistics and basic configurations but can't access API keys.",
  Member:
    "A member has basic privileges and can manage some settings in addition to having access to API keys.",
  Admin: "An organization admin can add and remove members to the organization.",
};
