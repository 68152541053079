import { useEffect } from "react";
import {
  Box,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerCloseButton,
  DrawerFooter,
  DrawerBody,
  Divider,
  Flex,
  Stack,
  useBoolean,
  useToast,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { GetApp, ImportExport } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { EnvironmentApi } from "svix/dist/openapi";

import { downloadAsJson, setErrors } from "@svix/common/formUtils";
import { formatDateTime, humanize } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import Form from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import Mono from "@svix/common/widgets/Mono";
import Stat from "@svix/common/widgets/Stat";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getApiConfiguration, getEEAdminApiConfiguration, getSvix } from "src/api";
import {
  OrganizationApi as DashboardOrganizationApi,
  OrganizationEEApi,
} from "src/generated/dashboard-openapi";
import { OrganizationOut } from "src/generated/dashboard-openapi/models/OrganizationOut";
import { OrganizationUpdate } from "src/generated/dashboard-openapi/models/OrganizationUpdate";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { refreshLoginInfo, switchEnvironment } from "src/store/auth";
import { useIsMemberOrAdmin } from "src/store/selectors";
import { getEnvTag, isEE, useLoadingManual } from "src/utils";
import ImportEnvironmentModal from "./ImportEnvironmentModal";

interface IEditEnvironmentModalProps {
  environment: OrganizationOut;
  isOpen: boolean;
  onClose: () => void;
}

export default function EditEnvironmentModal(props: IEditEnvironmentModalProps) {
  const dispatch = useAppDispatch();
  const isMemberOrAdmin = useIsMemberOrAdmin();
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId);
  const orgGroupId = useAppSelector((store) => store.auth.orgGroupId);
  const toast = useToast();
  const queryClient = useQueryClient();
  const [isShowingImportModal, setShowingImportModal] = useBoolean();

  const { isOpen, onClose, environment } = props;
  const isActiveEnv = environment.orgId === activeEnvId;
  const formCtx = useForm<OrganizationUpdate>();
  const { control, formState, reset } = formCtx;
  const [isDeleting, setDeleting] = useBoolean();

  useEffect(() => {
    reset(environment);
  }, [environment, reset]);

  const [, , exportEnvironment] = useLoadingManual(async () => {
    const sv = await getSvix();
    const api = new EnvironmentApi(sv._configuration);
    const exportedOrgData = await api.v1EnvironmentExport({});

    downloadAsJson(
      exportedOrgData,
      `svix-org-${environment.orgName}-${humanize(environment.orgId)}.json`
    );
  }, []);

  async function onDeleteEnvironment() {
    const region = environment.region;
    const envId = environment!.orgId;

    // FIXME: unify once the /organization endpoint is fully migrated to Rust
    if (isEE) {
      const api = new OrganizationEEApi(await getEEAdminApiConfiguration());
      await api.deleteOrgEeOrganizationOrgIdDelete(envId);
    } else {
      const api = new DashboardOrganizationApi(
        await getApiConfiguration({ region, noEnvSelected: true })
      );
      await api.deleteOrgOrganizationOrgIdDelete(envId);
    }

    toast({
      title: "Environment deleted",
      description: envId,
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    await refreshLoginInfo(dispatch);
    queryClient.invalidateQueries("environments");
    setDeleting.off();
    onClose();
  }

  async function onEditEnvironment(form: OrganizationUpdate) {
    try {
      if (isEE) {
        const api = new OrganizationEEApi(await getEEAdminApiConfiguration());
        await api.updateOrgEeOrganizationOrgIdPut(environment!.orgId, {
          ...environment,
          ...form,
        });
      } else {
        const api = new DashboardOrganizationApi(await getApiConfiguration());
        await api.updateOrgOrganizationOrgIdPut(environment!.orgId, {
          ...environment,
          ...form,
        });
      }
      toast({
        title: "Environment updated",
        description: `${environment!.orgId}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      await refreshLoginInfo(dispatch);
      queryClient.invalidateQueries("environments");
      onClose();
    } catch (e: any) {
      setErrors(formCtx.setError, e.body);
    }
  }

  return (
    <>
      <Drawer onClose={onClose} isOpen={isOpen} size="md">
        <DrawerOverlay />
        <DrawerContent borderRadius="lg">
          <DrawerHeader>Update Environment</DrawerHeader>
          <DrawerCloseButton />
          <DrawerBody>
            <Form onSubmit={onEditEnvironment} {...formCtx}>
              <Stack spacing={3} mb={6}>
                <TextField
                  control={control}
                  isDisabled={!isMemberOrAdmin}
                  name="orgName"
                  label="Name"
                  autoFocus
                  required
                  placeholder="e.g. Staging"
                />
                {isMemberOrAdmin && (
                  <SubmitButton size="sm" isLoading={formState.isSubmitting}>
                    Save
                  </SubmitButton>
                )}
              </Stack>
            </Form>
            <Stack spacing={3}>
              <Stat name="Region">
                {environment.region === "us" ? "United States" : "Europe"}
              </Stat>
              <Divider />
              <Stat name="Environment">
                {environment.orgEnv === "Test" ? "Development" : "Production"}
              </Stat>
              <Divider />
              <Stat name="Created at">{formatDateTime(environment.createdAt)}</Stat>
              <Divider />
              <Stat name="Last modified">{formatDateTime(environment.updatedAt)}</Stat>
              <Divider />
              <Stat name="Environment ID">
                <Mono>{environment.orgId}</Mono>
              </Stat>
              <Stat name="Organization Group ID">
                <Mono>{orgGroupId}</Mono>
              </Stat>
            </Stack>
            <Flex mt="auto" flexGrow={1} />
          </DrawerBody>
          <DrawerFooter>
            <Stack w="100%" spacing={3}>
              <Tooltip
                label={isActiveEnv && "Environment is already active"}
                openDelay={200}
                placement="top"
              >
                <Box>
                  <Button
                    w="100%"
                    colorScheme="gray"
                    isDisabled={!isMemberOrAdmin || isActiveEnv}
                    variant="outline"
                    onClick={async () => switchEnvironment(environment, dispatch)}
                    size="sm"
                  >
                    Set Active
                  </Button>
                </Box>
              </Tooltip>
              <Tooltip
                label={!isActiveEnv && "You can only import into the active environment"}
                placement="top"
              >
                <Box>
                  <Button
                    w="100%"
                    colorScheme="gray"
                    isDisabled={!isActiveEnv}
                    variant="outline"
                    onClick={setShowingImportModal.on}
                    leftIcon={<ImportExport fontSize="small" />}
                    size="sm"
                  >
                    Import
                  </Button>
                </Box>
              </Tooltip>
              <Tooltip
                label={!isActiveEnv && "Only the active environment can be exported"}
                placement="top"
              >
                <Box>
                  <Button
                    w="100%"
                    colorScheme="gray"
                    isDisabled={!isActiveEnv}
                    variant="outline"
                    onClick={exportEnvironment}
                    leftIcon={<GetApp fontSize="small" />}
                    size="sm"
                  >
                    Export
                  </Button>
                </Box>
              </Tooltip>
              <Divider />
              <Tooltip
                closeOnClick={false}
                label={
                  !isMemberOrAdmin
                    ? "Insufficient access for managing environments"
                    : isActiveEnv && "Cannot delete the active environment"
                }
                shouldWrapChildren
                openDelay={200}
              >
                <Button
                  colorScheme="red"
                  onClick={setDeleting.on}
                  size="sm"
                  isDisabled={!isMemberOrAdmin || isActiveEnv}
                  w="100%"
                >
                  Delete
                </Button>
              </Tooltip>
            </Stack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <ConfirmationDialog
        typeToConfirmStr={`${getEnvTag(environment!).toLowerCase()}/${
          environment.orgName
        }`}
        colorScheme="red"
        title={`Delete ${environment.orgName}?`}
        isOpen={isDeleting}
        onCancel={setDeleting.off}
        onOk={onDeleteEnvironment}
        labelOk="Delete"
      >
        <Text>
          Are you sure you would want to delete this environment This operation cannot be
          undone.
        </Text>
      </ConfirmationDialog>
      <ImportEnvironmentModal
        isOpen={isShowingImportModal}
        onClose={setShowingImportModal.off}
      />
    </>
  );
}
