import { Tr } from "@chakra-ui/react";
import { ApplicationOut } from "svix";

import { formatDate } from "@svix/common/utils";
import TableCell from "@svix/common/widgets/TableCell";

import { routeResolver } from "../../App";

interface IEventTypeRowProps {
  application: ApplicationOut;
}

export default function ApplicationRow({ application }: IEventTypeRowProps) {
  return (
    <Tr key={application.name}>
      <TableCell
        to={routeResolver.getRoute("applications._id", { appId: application.id })}
        align="left"
      >
        {application.name}
      </TableCell>
      <TableCell
        to={routeResolver.getRoute("applications._id", { appId: application.id })}
      >
        {application.uid ?? ""}
      </TableCell>
      <TableCell
        to={routeResolver.getRoute("applications._id", { appId: application.id })}
      >
        {application.id}
      </TableCell>
      <TableCell
        to={routeResolver.getRoute("applications._id", { appId: application.id })}
        textAlign="right"
      >
        {formatDate(application.createdAt)}
      </TableCell>
    </Tr>
  );
}
