import {
  HStack,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuGroup,
  MenuDivider,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { Person } from "@material-ui/icons";

import { isOIDCEE } from "@svix/common/constants";
import { getAuth0ProviderName } from "@svix/common/utils";
import SSOAccountTag from "@svix/common/widgets/SSOAccountTag";

import { useAppDispatch } from "src/hooks/store";
import { logoutEE } from "src/store/auth";
import { useSvixAuth, isEE } from "src/utils";

export default function AccountBarItem({ children }: { children: React.ReactNode }) {
  const dispatch = useAppDispatch();
  const { user, logout } = useSvixAuth();

  function logoutClicked() {
    if (isEE) {
      dispatch(logoutEE());

      if (isOIDCEE) {
        logout();
      }
    } else {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
  }

  const emailTitle = user?.email;
  const provider = getAuth0ProviderName(user?.sub || "");

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          variant="appbar"
          aria-label="show account"
          data-cy="account-menu"
          lineHeight="0"
        >
          <Person />
        </MenuButton>
        <MenuList>
          <MenuGroup>
            {user && (
              <>
                <HStack px="0.8rem" py="0.4rem">
                  <Text fontSize="sm" fontWeight="semibold">
                    {emailTitle}
                  </Text>
                  <SSOAccountTag provider={provider} />
                </HStack>
                <MenuDivider />
              </>
            )}
            {children}
            <MenuItem onClick={logoutClicked}>Logout</MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </>
  );
}
