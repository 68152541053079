import {
  Box,
  HStack,
  Image,
  Stack,
  Text,
  useRadio,
  UseRadioProps,
  BoxProps,
} from "@chakra-ui/react";

type IntegrationTileProps = UseRadioProps & {
  icon: string;
  name: string;
  description?: string;
  wrapper?: BoxProps;
};

export default function IntegrationTile(props: IntegrationTileProps) {
  const { name, icon, description, wrapper } = props;
  const { getInputProps, getRadioProps } = useRadio(props);
  const input = getInputProps();
  const radio = getRadioProps();

  return (
    <Box as="label" w="16em" {...wrapper}>
      <input {...input} />
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        py={3}
        px={4}
        _hover={{ bg: "background.secondary" }}
        cursor="pointer"
        {...radio}
        _checked={{
          outlineColor: "brand.600",
          outlineWidth: "2px",
          outlineStyle: "solid",
        }}
      >
        <HStack spacing={4}>
          <Image src={icon} alt={name} width="24px" height="24px" />
          <Stack justifyContent="center" minH="32px" spacing={0}>
            <Text textAlign="left" fontWeight="semibold">
              {name}
            </Text>
            {description && (
              <Text textAlign="left" fontSize="sm" color="text.secondary">
                {description}
              </Text>
            )}
          </Stack>
        </HStack>
      </Box>
    </Box>
  );
}
