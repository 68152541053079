import {
  Divider,
  FormControl,
  FormLabel,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory } from "react-router";

import Button from "@svix/common/widgets/Button";
import Mono from "@svix/common/widgets/Mono";

import useEngineerMode from "src/hooks/engineerMode";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import {
  disableFlag,
  enableFlag,
  IFeatureFlag,
  IFeatureFlags,
} from "src/store/featureFlags";

export default function EngineerMode() {
  const isEngineer = useEngineerMode();
  const { activeEnvId, orgGroupId } = useAppSelector((state) => state.auth);
  const featureFlags: IFeatureFlags = useAppSelector((state) => state.featureFlags);

  const history = useHistory();
  const switchToCustomer = () => {
    history.push({
      pathname: history.location.pathname,
      search: "?_svix_engineer=false",
    });
  };

  if (!isEngineer) {
    return null;
  }

  return (
    <div style={{ position: "fixed", bottom: 0, right: "5px", zIndex: 9999 }}>
      <Popover placement="top">
        <PopoverTrigger>
          <Button
            leftIcon={<VisibilityIcon />}
            colorScheme="yellow"
            borderRadius="5px 5px 0 0"
          >
            Engineer
          </Button>
        </PopoverTrigger>
        <PopoverContent mr="5px">
          <PopoverHeader fontWeight="semibold">Viewing as Engineer</PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            <Stack>
              <Stack spacing={1}>
                <Text fontWeight="semibold">Org group</Text>
                <Mono>{orgGroupId}</Mono>
              </Stack>
              <Divider />
              <Stack spacing={1}>
                <Text fontWeight="semibold">Org</Text>
                <Mono>{activeEnvId}</Mono>
              </Stack>
              <Divider />
              <Stack spacing={1}>
                <Text fontWeight="semibold">Feature flags</Text>
                {Object.entries(featureFlags).map(([key, value]) => {
                  return <FeatureFlagItem key={key} flag={key} enabled={value} />;
                })}
              </Stack>
              <Divider />
              <Button variant="outline" size="sm" onClick={switchToCustomer}>
                Switch to Customer
              </Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
}

function FeatureFlagItem(props: { flag: string; enabled: boolean }) {
  const { flag, enabled } = props;
  const dispatch = useAppDispatch();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enabled = e.target.checked;
    if (enabled) {
      dispatch(enableFlag(flag as IFeatureFlag));
    } else {
      dispatch(disableFlag(flag as IFeatureFlag));
    }
  };

  return (
    <FormControl display="flex">
      <Switch id={`flag-switch-${flag}`} isChecked={enabled} onChange={onChange} />
      <FormLabel htmlFor={`flag-switch-${flag}`} fontWeight={500} fontSize="sm" my={0}>
        <Mono ml={1}>{flag}</Mono>
      </FormLabel>
    </FormControl>
  );
}
