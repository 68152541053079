import { Icon, Tag, Tooltip, Td, Tr, useBoolean, Flex } from "@chakra-ui/react";

import TableCell from "@svix/common/widgets/TableCell";

import { getRegionConfig } from "src/constants";
import { OrganizationOut } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import { getEnvTag } from "src/utils";
import EditEnvironmentModal from "./EditEnvironmentModal";

interface IEnvironmentRowProps {
  environment: OrganizationOut;
}

export default function EnvironmentRow(props: IEnvironmentRowProps) {
  const { environment } = props;
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId);
  const [isEditing, setEditing] = useBoolean();

  const isActive = environment.orgId === activeEnvId;
  const regionConfig = getRegionConfig(environment.region);

  return (
    <>
      <Tr>
        <Td w="32px" textTransform="uppercase" textAlign="center" onClick={setEditing.on}>
          <Tooltip label={regionConfig.label}>
            <Flex alignItems="center">
              <Icon boxSize={8} as={regionConfig.flag} />
            </Flex>
          </Tooltip>
        </Td>
        <TableCell
          onClick={setEditing.on}
          fontWeight={isActive ? "semibold" : undefined}
          w="30%"
        >
          {environment.orgName}
          {isActive && (
            <Tag size="sm" ml={2} colorScheme="green">
              Active
            </Tag>
          )}
        </TableCell>
        <TableCell onClick={setEditing.on} w="33%" maxW={0} isTruncated mono>
          {environment.orgId}
        </TableCell>
        <TableCell onClick={setEditing.on}>
          {getEnvTag(environment)}

          {/* XXX: Put inside the table cell since <span> cannot be descendent of <tbody> */}
          <EditEnvironmentModal
            environment={environment}
            isOpen={isEditing}
            onClose={setEditing.off}
          />
        </TableCell>
      </Tr>
    </>
  );
}
