import { Box, ModalFooter, Button, Stack, FormLabel } from "@chakra-ui/react";
import { ArrowForward } from "@material-ui/icons";
import { useForm } from "react-hook-form";

import { logError } from "@svix/common/logger";
import TextArea from "@svix/common/widgets/form/TextArea";
import TextField from "@svix/common/widgets/form/TextField";

import { getApiConfiguration } from "src/api";
import { AuthenticationApi, QuestionnaireIn } from "src/generated/dashboard-openapi";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { updateUserInfo } from "src/store/auth";
import { trackEvent } from "../../analytics";

interface WelcomeQuestionnaireProps {
  onClose: () => void;
}

export default function WelcomeQuestionnaire(props: WelcomeQuestionnaireProps) {
  const dispatch = useAppDispatch();
  const { fullName } = useAppSelector((state) => state.auth);

  const { handleSubmit, control } = useForm<QuestionnaireIn & { fullName: string }>({
    defaultValues: {
      fullName,
      defineSuccess: "",
      // Unused. We still send them because the backend expects them.
      referredBy: "",
      alreadySending: "",
      howMany: "",
      mainResponsibility: "",
      mainResponsibilityOther: "",
    },
  });

  const onSubmit = async (form: QuestionnaireIn & { fullName: string }) => {
    dispatch(updateUserInfo({ fullName: form.fullName }));

    const config = await getApiConfiguration();
    const authApi = new AuthenticationApi(config);

    // Don't 'await', so it doesn't block progress in the onboarding
    authApi
      .setWelcomeQuestionnaireAuthenticationWelcomeQuestionnairePost({
        ...form,
      })
      .catch((e) => {
        logError(e, "Error submitting questionnaire");
      });

    authApi
      .updateMetadataAuthenticationMetadataPatch({
        name: form.fullName,
      })
      .catch((e) => {
        logError(e, "Error submitting full name");
      });

    trackEvent("Questionnaire submitted");

    props.onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={8} padding="2em" pb="0em">
        <TextField
          autoFocus
          required
          control={control}
          name="fullName"
          label="Full name"
          placeholder="John Doe"
          size="lg"
        />
        <Box>
          <FormLabel>What brought you to Svix?</FormLabel>
          <TextArea name="defineSuccess" control={control} required />
        </Box>
      </Stack>
      <ModalFooter>
        <Button colorScheme="blue" type="submit" rightIcon={<ArrowForward />}>
          Continue
        </Button>
      </ModalFooter>
    </form>
  );
}
