import { useEffect } from "react";
import { GenericError } from "@auth0/auth0-spa-js";
import { useLocation, useParams } from "react-router-dom";

import { logError, logInfo } from "@svix/common/logger";
import { getApiError, isApiError } from "@svix/common/utils";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";

import { loginFailedCode } from "src/constants";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { popError } from "src/store/errors";
import { useSvixAuth } from "src/utils";

export default function GlobalErrors() {
  const { logout, loginWithRedirect } = useSvixAuth();
  const dispatch = useAppDispatch();
  const error = useAppSelector((state) => state.errors[0]) as Error | undefined;
  const { auth0ConnectionName } = useParams<{ auth0ConnectionName?: string }>();
  const location = useLocation();

  useEffect(() => {
    if (error) {
      if (error.message === "Failed to fetch") {
        // No need to log this - temporary network issue locally
      } else if (isSessionExpiredError(error)) {
        logInfo(error);
      } else if (isLoginRequiredError(error)) {
        loginWithRedirect({
          authorizationParams: {
            connection: auth0ConnectionName,
            redirect_uri: `${window.location.origin}/login`,
          },
          appState: {
            returnTo: location.pathname,
          },
        });
      } else {
        logError(error);
      }
    }
  }, [error]); // eslint-disable-line react-hooks/exhaustive-deps

  const okClicked = () => {
    if (isApiError(error) && error.body.code === loginFailedCode) {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }
    dispatch(popError());
  };

  if (!error || isLoginRequiredError(error)) {
    // loginWithRedirect is asynchronous. This avoids showing the error dialog for a split second.
    return null;
  }

  return (
    <ConfirmationDialog
      title="An error has occurred"
      isOpen
      labelOk="Okay"
      onOk={okClicked}
    >
      {getApiError(error)}
    </ConfirmationDialog>
  );
}

function isSessionExpiredError(e: Error): boolean {
  return isApiError(e) && e.body.code === "authentication_failed";
}

function isLoginRequiredError(e: Error): boolean {
  return e instanceof GenericError && e.error === "login_required";
}
