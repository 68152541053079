import { useCallback } from "react";
import {
  Box,
  Collapse,
  IconButton,
  HStack,
  Heading,
  Grid,
  GridItem,
  Link as StyledLink,
  Stack,
  Text,
  useBoolean,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useForm, UseFormWatch, UseFormSetError } from "react-hook-form";
import { useQueryClient } from "react-query";
import { EventTypeUpdate } from "svix";
import { EventTypeFromOpenApi, EventTypeOut } from "svix/dist/openapi";

import * as C from "@svix/common/constants";
import { setErrors } from "@svix/common/formUtils";
import Card from "@svix/common/widgets/Card";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import SchemaPreviewer from "@svix/common/widgets/JsonSchema/SchemaPreviewer";
import { isSchemaConfigured } from "@svix/common/widgets/JsonSchema/SchemaPreviewer/utils";
import { JSONSchema7 } from "@svix/common/widgets/JsonSchema/types";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { trackEvent } from "src/analytics";
import { getSvix } from "src/api";
import { useAppSelector } from "src/hooks/store";

interface OpenAPIEventTypeRowProps {
  eventType: EventTypeFromOpenApi;
}

type EventTypeForm = Omit<EventTypeOut, "schemas"> & {
  schema?: JSONSchema7;
  svixName: string;
};

export function OpenAPIEventTypeRow(props: OpenAPIEventTypeRowProps) {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId);
  const queryClient = useQueryClient();
  const toast = useToast();

  const { eventType } = props;
  const defaultValues = {
    ...eventType,
    schema: eventType.schemas?.["1"],
    svixName: eventType.name || "",
  };
  const formCtx = useForm<EventTypeForm>({ defaultValues, mode: "onChange" });

  // TS really doesn't like the JSONSchema7 type in 'schema'
  // The JSONSchema7 type has recursive references that, when used with react-hook-form,
  // throw a 'Type instantiation is excessively deep and possibly infinite' error.
  const setError = formCtx.setError as UseFormSetError<any>;
  const watch = formCtx.watch as UseFormWatch<Omit<EventTypeForm, "schema">>;

  const [saved, hasSaved] = useBoolean(false);
  const { isOpen, onToggle } = useDisclosure();

  const saveEventType = useCallback(
    async (form: EventTypeForm) => {
      const api = await getSvix();
      const eventType: EventTypeUpdate = { ...form };

      if (form.schema) {
        eventType.schemas = {
          1: {
            ...form.schema,
          },
        };
      }

      try {
        await api.eventType.update(form.svixName, eventType);
        toast({ status: "success", title: `Saved ${form.svixName}` });
        queryClient.invalidateQueries(["environments", activeEnvId, "eventTypes"]);
        trackEvent("Add Event Type", {
          label: "OpenAPI",
        });
        hasSaved.on();
      } catch (e) {
        setErrors(setError, e.body);
      }
    },
    [activeEnvId, setError, hasSaved, queryClient, toast]
  );

  const svixName = watch("svixName");

  return (
    <Box py={3}>
      <Form {...formCtx} onSubmit={saveEventType}>
        <Grid templateColumns="1fr 1fr">
          <GridItem colSpan={1}>
            <Box alignItems="center" display="flex">
              <IconButton
                size="sm"
                onClick={onToggle}
                mr="1em"
                aria-label="Show schema"
                icon={isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              />
              <Text variant="lg">{eventType.name}</Text>
            </Box>
          </GridItem>
          <GridItem>
            <HStack justifyContent="right">
              <Stack spacing={2} mr={2}>
                <TextField
                  minW="20em"
                  control={formCtx.control}
                  name="svixName"
                  autoFocus
                  required
                  placeholder="e.g. invoice.paid"
                  isDisabled={saved}
                />
                <GeneralFormErrors />
              </Stack>
              <SubmitButton disabled={saved || svixName.length === 0}>
                {saved ? "Saved" : "Save"}
              </SubmitButton>
            </HStack>
          </GridItem>
        </Grid>
      </Form>
      <Collapse in={isOpen}>
        <Stack>
          <Text variant="lg" as="b" pt={2}>
            Description
          </Text>
          <Text variant="lg" mt={0} maxWidth="40rem">
            {eventType.description}
          </Text>
          {eventType.groupName && (
            <>
              <Text variant="lg" as="b" pt={2}>
                Group name
              </Text>
              <Text variant="lg" mt={0} maxWidth="40rem">
                {eventType.groupName}
              </Text>
            </>
          )}
          {eventType.featureFlag && (
            <>
              <Text variant="lg" as="b" pt={2}>
                Feature flag
              </Text>
              <Text variant="lg" mt={0} maxWidth="40rem">
                {eventType.featureFlag}
              </Text>
            </>
          )}
          <Text variant="lg" as="b" pt={2}>
            Schema
          </Text>
          <SchemaPreview schema={defaultValues.schema} />
        </Stack>
      </Collapse>
    </Box>
  );
}

const SchemaPreview = ({ schema }: { schema?: JSONSchema7 }) => {
  if (!schema || !isSchemaConfigured(schema)) {
    return (
      <Card w="100%" mt={8}>
        <Heading as="h2" size="sm" color="text.danger">
          No properties found for schema
        </Heading>
        <Text variant="caption" mt={2}>
          This schema was not found in the OpenAPI spec or doesn't conform to the
          JSONSchema Draft 7 specifications.
        </Text>

        <Text variant="caption" mt={1} mb={4}>
          Update this schema to allow your customers to properly interpret this event
          type.
          <StyledLink
            isExternal
            ml={1}
            href={C.docs.eventTypes.openapiImport}
            color="brand.500"
            fontWeight="medium"
          >
            Learn More
          </StyledLink>
        </Text>
      </Card>
    );
  }

  return <SchemaPreviewer schema={schema} version={1} />;
};
